import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Grid, Typography } from '@mui/material';
import apiConfig from "./../../apiConfig/config";

const HomeVideo = () => {
    const [homeContentFeature, setHomeContentFeature] = useState({});

    const getStaticFeatureContent = async () => {
        try {
            const featureTypes = ["Banner_video_Management"];
            const apiRequests = featureTypes.map(type =>
                axios.get(apiConfig.viewStaticContent, { params: { type } })
            );

            const responses = await Promise.allSettled(apiRequests);

            const updatedContent = responses.reduce((acc, response, index) => {
                if (response.status === "fulfilled" && response.value.data.responseCode === 200) {
                    acc[`video${index + 1}`] = response.value.data.result;
                } else {
                    console.error(`Request ${index + 1} failed:`, response.reason);
                }
                return acc;
            }, {});

            setHomeContentFeature(updatedContent);
        } catch (error) {
            console.error("Something went wrong. Please try again.", error);
        }
    };

    useEffect(() => {
        getStaticFeatureContent();
    }, []);

    return (
        <Box>
            <Grid item xs={12} sm={12} lg={12}>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    {homeContentFeature.video1?.video ? (
                        <video
                            autoPlay
                            loop
                            muted
                            src={homeContentFeature.video1.video}
                            style={{
                                width: '100%',
                                objectFit: 'cover',
                                height: '70vh',
                            }}
                        >
                            Your browser does not support the video tag.
                        </video>
                    ) : null}

                </Box>
            </Grid>
        </Box>
    );
};

export default HomeVideo;
