import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import apiConfig from "./../../apiConfig/config";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function ResetPassword({ open, onClose, email }) {
  const [isloading, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoader(true);
      const res = await axios({
        method: "POST",
        url: apiConfig.resetPassword,
        data: { ...values, email: email },
      });
      if (res.data.responseCode === 200) {
        resetForm();
        setLoader(false);
        toast.success(res.data.responseMessage || "Password reset successful.");
        onClose();
      } else {
        toast.error(
          res.data.responseMessage || "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      toast.error(
        error.response.data.responseMessage || "Failed to reset password."
      );
      setLoader(false);
    }
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
      .required("Please enter password."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Please confirm your new password."),
    email: Yup.string(),
  });

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: "400px",
          padding: "2.5rem",
          border: "1px solid #e7e7e7",
          "@media(max-width:600px)": {
            width: "330px",
            padding: "0.5rem",
          },
          "@media(max-width:380px)": {
            width: "310px",
          },
          "@media(max-width:330px)": {
            width: "262px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
            // borderRadius:"20px"
          }}
        >
          <img
            src="/resetpassword.png"
            height="53px"
            width="53px"
            alt="resetpassword"
          />
          <Typography
            sx={{
              color: "#000",
              fontSize: "20px",
              fontWeight: 700,
              marginTop: "10px",
              fontFamily: "Nunito",
              textTransform: "capitalize",
              "@media(max-width:600px)": {
                fontSize: "16px",
              },
            }}
          >
            Reset Password
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, handleChange, handleBlur, isValid }) => (
            <Form>
              <Box gap={2} sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  fullWidth
                  placeholder="Enter New Password"
                  variant="outlined"
                  size="small"
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "Nunito",
                      height: "44px",
                      gap: "10px",
                      padding: "8px",
                      borderRadius: "5px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <AiOutlineEye
                              style={{
                                color: "#BCBCBC",
                                marginRight: "12px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          ) : (
                            <AiOutlineEyeInvisible
                              style={{
                                color: "#BCBCBC",
                                marginRight: "12px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0.1%",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  placeholder="Confirm New Password"
                  variant="outlined"
                  size="small"
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  onPaste={(e) => e.preventDefault()} // Prevent pasting
                  onCopy={(e) => e.preventDefault()} // Prevent copying
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "Nunito",
                      height: "44px",
                      gap: "10px",
                      padding: "8px",
                      borderRadius: "5px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <AiOutlineEye
                              style={{
                                color: "#BCBCBC",
                                marginRight: "12px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          ) : (
                            <AiOutlineEyeInvisible
                              style={{
                                color: "#BCBCBC",
                                marginRight: "12px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={touched.confirmPassword && !!errors.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0.1%",
                    },
                  }}
                />
              </Box>
              <Box sx={{ marginTop: "15px" }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!isValid || isloading}
                  style={{
                    height: "44px",
                    padding: "8px",
                    gap: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#00C6FF",
                  }}
                >
                  {isloading ? (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  ) : (
                    "SUBMIT"
                  )}
                  
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}

export default ResetPassword;
