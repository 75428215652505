import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Dialog,
  Divider,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import OTPInput from "otp-input-react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../context/Auth";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

import toast from "react-hot-toast";
import { patchDataHandler } from "../../../src/apiConfig/service";
import { BorderColor } from "@mui/icons-material";
import Apiconfigs from "../../apiConfig/config";

const useStyles = makeStyles((theme) => ({
  loginMainBox: {
    "& .bodyBox": {
      "& .otpFormControl": {
        "& input": {
          width: "45.65px !important",
          border: "0.91px solid #0000001A !important",
          height: "45.65px !important",
          background: "white",
          borderRadius: "5px",

          marginRight: "0px !important",
          "@media(max-width:460px)": {
            width: "41px !important",
            height: "41px !important",
          },
          "@media(max-width:380px)": {
            width: "31px !important",
            height: "31px !important",
          },
        },
      },
      // "& .texttypo": {
      //   color: theme.palette.text.extralight,
      //   wordBreak: "break-all",
      //   "& span": {
      //     //color: theme.palette.error.main,
      //   },
      // },
      // "& .resendMsg": {
      //   "& span": {
      //     fontSize: "16px",
      //   },
      //   "& .MuiButton-root.Mui-disabled": {
      //     color: "rgba(237, 33, 36, 0.50)",
      //   },
      //   "& .resend": {
      //     color: theme.palette.error.main,
      //     fontSize: "14px",
      //   },
      // },
    },
  },
}));
const VerifyOTP = ({ open, onClose, email, toggleReset, setEmail }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [timer, setTimer] = useState(null); // Initial timer value in seconds
  useEffect(() => {
    setTimer(180);
  }, [open]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(countdown);
      if (open) {
        toast.error("Time expired!");
      }
    }

    return () => clearInterval(countdown);
  }, [timer]);

  // const handleSignInClose = () => {
  //   onClose();
  // };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      // values.email = location?.state?.email;
      const response = await patchDataHandler("verifyOTP", {
        ...values,
        email: email,
      });
      if (response.responseCode === 200) {
        setIsLoading(false);

        toggleReset(); // Call toggleOTP function to show/hide OTP popup

        onClose();
        // navigate.push("/reset-password");
        // toast.success(response?.responseMessage);
      }
      setIsLoading(false);
    } catch (err) {
      // toast.error(err?.response?.data?.responseMessage);
      setIsLoading(false);
    }
  };

  const handleResendOTP = async (values) => {
    try {
      const response = await axios({
        method: "PUT",
        url: Apiconfigs.resendOTP,
        data: { ...values, email: email },
      });
      if (response.data.responseCode === 200) {
        setTimer(180);
        toast.success(response.data.responseMessage || "OTP send successful.");
      } else {
        toast.error(
          response.data.responseMessage ||
            "Something went wrong. Please try again."
        );
      }
    } catch (error) {}
  };

  const initialValues = {
    otp: "",
    email: "",
  };

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("Please enter OTP."),
    email: Yup.string(),
  });
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box
        className={classes.loginMainBox}
        sx={{
          width: "400px",
          padding: "2.5rem",
          border: "1px solid #e7e7e7",
          "@media(max-width:600px)": {
            width: "330px",
            padding: "0.5rem",
          },
          "@media(max-width:380px)": {
            width: "310px",
          },
          "@media(max-width:330px)": {
            width: "262px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
            // borderRadius:"20px"
          }}
        >
          <img src="/OTP.png" height="57px" width="57px" alt="OTP" />
          <Typography
            sx={{
              color: "#000",
              fontSize: "20px",
              fontWeight: 700,
              fontFamily: "Nunito",
              marginTop: "10px",
              textTransform: "capitalize",
              "@media(max-width:600px)": {
                fontSize: "16px",
              },
            }}
          >
            OTP Verifications
          </Typography>
          <Typography
            sx={{
              width: "314px",
              height: "40px",
              fontSize: "12px",
              fontFamily: "Nunito",
              textAlign: "center",
              color: "#636880",
              marginTop: "5px",
              lineHeight: "20px",
            }}
          >
            Please enter the 6 digit verification code that was sent to your
            registered email. The code is valid for 3 minute.
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            isValid,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <Box
                className="bodyBox"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <FormControl className="otpFormControl displayCenter">
                  <OTPInput
                    value={values.otp}
                    name="otp"
                    inputVariant="outlined"
                    autoComplete="off"
                    onChange={(e) => {
                      setFieldValue("otp", e);
                    }}
                    autoFocus
                    OTPLength={6}
                    error={Boolean(touched.otp && errors.otp)}
                    otpType="number"
                    disabled={isLoading}
                    style={{
                      justifyContent: "space-between",
                      gap: "19.2px ",
                      width: "400px",
                    }}
                  />
                </FormControl>
              </Box>
              {touched.otp && errors.otp && (
                <Typography mt={1} variant="body2" color="error">
                  {errors.otp}
                </Typography>
              )}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                {timer === 0 ? (
                  <Typography
                    sx={{
                      fontFamily: "Nunito",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "19.78px",
                      textAlign: "left",
                      color: "#00C6FF",
                      cursor: "pointer",
                    }}
                    onClick={() => handleResendOTP()}
                  >
                    Resend OTP
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Nunito",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "19.78px",
                      textAlign: "left",
                      color: "#A0A0A0", // Color for disabled state
                      cursor: "not-allowed", // Change cursor for disabled state
                    }}
                  >
                    Resend OTP
                  </Typography>
                )}

                <Typography
                  sx={{
                    fontFamily: "Nunito",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "19.78px",
                    textAlign: "right",
                    color: "#DC0404",
                  }}
                >
                  {Math.floor(timer / 60)}:
                  {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                </Typography>
              </Box>

              <Box sx={{ marginTop: "10px" }}>
                <Button
                  // onClick={handleSignInClose}
                  style={{
                    height: "44px",
                    padding: "8px",
                    gap: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#00C6FF",
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!isValid || isLoading || timer === 0}
                  // LinkComponent={NavLink}
                  // to="/ssubscription"
                >
                  {isLoading ? (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default VerifyOTP;
