import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import classes from "./buyteddi.module.css";
import Product from "../../components/product/Product";
import Pagination from "@mui/material/Pagination";

const BuyTeddi = () => {
  const handleShopNowClick = (event) => {
    event.preventDefault();
    const productSection = document.getElementById("product");
    productSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Grid
        container
        maxWidth
        sx={{
          background: "linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%)",
          padding: "3% 9%",
        }}
      >
        <Grid item xs={12} sm={12} md={5} lg={5} display={'flex'} justifyContent={'center'}>
          <Box className={classes.imgContainer}>
            <div className={classes.mainImg}><div className={classes.uptoImg} /></div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Box
            sx={{
              "@media (min-width: 1024px) and (max-width: 1416px)": {
                paddingLeft: "3rem",
              },
            }}
          >
            <Typography
              sx={{
                color: "#000",
                fontSize: "3rem",
                fontWeight: 900,
                fontFamily: "Nunito",
                lineHeight: "2.9rem",
              }}
            >
              Discover Teddy Tale Wonders Around You
            </Typography>
            <Typography
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Nunito",
                paddingTop: "10px",
              }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "2rem" }}>
            <a href="#product" onClick={handleShopNowClick}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#00C6FF",
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  fontWeight: 400,
                  textTransform: "none",
                  "@media (min-width: 1024px) and (max-width: 1416px)": {
                    marginLeft: "3rem",
                  },
                }}
              >
                Shop Now
              </Button>
            </a>
          </Box>
        </Grid>
      </Grid>
      <Box id="product" sx={{ padding: "3% 9%" }}>
        <Typography
          sx={{
            fontSize: "3rem",
            color: "#000",
            fontFamily: "Nunito",
            fontWeight: 800,
            textAlign: "center",
          }}
        >
          Products
        </Typography>
      </Box>
      <Grid
        container
        fullWidth
        display={"flex"}
        justifyContent={"center"}
        sx={{ padding: "0 9%" }}
      >
        {[1, 2, 3, 4].map((index) => (
          <Grid item lg={3}>
            <Box key={index}>
              <Product
                image={"/teddi.png"}
                name={"Pinky Teddy"}
                price={"500"}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <Pagination count={22} shape="rounded" />
      </Box>
    </>
  );
};

export default BuyTeddi;
