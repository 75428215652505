import React, { useState, useEffect, useContext } from "react";
import "./subscriptionPlans.css";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import SignInPage from "../signIn";
import SignUpPage from "../signUp";
import ForgotPassword from "../forgotPassword";
import axios from "axios";
import apiConfig from "./../../apiConfig/config";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../context/Auth";
import PlansCard from "../../components/Plan/PlansCard";
import VerifyOTP from "../verifyOTP";
import Billing from "../../components/Billing/Billing";
import Home from "../home";
import ResetPassword from "../resetPassword/ResetPassword";
import { getAuthToken } from'../../utils/index';
import classes from "./subscriptionPlans.css";

const authToken = getAuthToken();
const styles = {
  tableContent: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "21.82px",
  },
  subscText: {
    fontFamily: "Rowdies",
    fontSize: "4rem",
    marginTop: "100px",
    fontWeight: "700",
    lineHeight: "50px",
    letterSpacing: "0.03em",
    textAlign: "center",
    WebkitTextStrokeWidth: "4px",
    WebkitTextStrokeColor: "#000048",
    WebkitTextFillColor: "#00BAFF",
    "@media (max-width: 450px)": {
      fontFamily: "Rowdies",
      fontSize: "3rem",
      marginTop: "100px",
      fontWeight: "700",
      lineHeight: "50px",
      letterSpacing: "0.03em",
      textAlign: "center",
      WebkitTextStrokeWidth: "4px",
      WebkitTextStrokeColor: "#000048",
      WebkitTextFillColor: "#00BAFF",
    },
  },
};

const Subscription = () => {
  const [openSignIn, setOpenSignIn] = useState(false);
  const location = useLocation();
  const auth = useContext(AuthContext);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [subsPlan, setSubsPlan] = useState();
  const [redirectUrl, setRedirectUrl] = useState(""); // Add state to store redirect URL
  const [email, setEmail] = useState("");
  const [planCards, setPlanCards] = useState([]);
  const [prices, setPrices] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleSignInOpen = () => {
    if (!auth?.userLoggedIn) setOpenSignIn(true);
  };

  const handleSignInTogle = () => {
    setOpenSignIn(!openSignIn);
  };

  const handleForgotInOpen = () => {
    setOpenForgot(true);
  };

  const handleSignInClose = () => {
    setOpenSignIn(false);
  };

  const handleForgotInClose = () => {
    setOpenForgot(false);
  };

  const handleOTPInClose = () => {
    setOpenOTP(false);
  };

  const handleSignUpTogle = () => {
    setOpenSignUp(!openSignUp);
  };

  const handleForgotTogle = () => {
    setOpenForgot(!openForgot);
  };

  const handleOTPTogle = () => {
    setOpenOTP(!openOTP);
  };

  const handleResetTogle = () => {
    setOpenReset(!openReset);
  };

  const handleResetInClose = () => {
    setOpenReset(false);
  };

 const getAvailablePlans = async () => {
    try {
      setLoader(true);
      const res = await axios({
        method: "GET",
        url: apiConfig.stripeProducts, // Use the new API endpoint from Apiconfigs
        headers: {
          Authorization: authToken,
                        
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        let data = res.data.data;

        const productIds = ['prod_Q9ohip9T3Wops8', 'prod_Q9oiMeybYcAUlb', 'prod_Q9oidsLUwO1QeP'];
        const filteredData = data.filter(product => productIds.includes(product.id));

        const formattedData = productIds.map(productId => {
          const product = filteredData.find(item => item.id === productId);
          return {
            id: product.id,
            description: product.description,
            name: product.name,
            active: product.active,
            features: product.features ? product.features.map(feature => feature.name) : [],
          };
        });

        setPlanCards(formattedData);
      } else {
        setPlanCards([]);
      }
    } catch (error) {
      console.error('Error fetching Stripe products:', error);
      setPlanCards([]);
    } finally {
      setLoader(false);
    }
  };

  const getPrices = async () => {
    try {
      setLoader(true);
      const res = await axios({
        method: "GET",
        url: apiConfig.stripeProductsPrices,
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        const data = res.data.data; 
        if (data && Array.isArray(data)) {
          const formattedData = data.map((price) => ({
            product: price.product,
            unit_amount: price.unit_amount / 100,
            currency: price.currency,
          }));
          setPrices(formattedData);
        } else {
          setPrices([]); 
        }
      } else {
        setPrices([]); // Handle case when response status is not 200
      }
    } catch (error) {
      console.error('Error fetching Stripe prices:', error);
      setPrices([]); // Handle case when there's an error
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAvailablePlans();
    getPrices();
  }, []);

  // Combine planCards and prices based on product id
  const combinedPlanData = planCards.map((card) => {
    const price = prices.find((price) => price.product === card.id);
    return {
      ...card,
      price: price ? price.unit_amount : 'N/A',
      currency: price ? price.currency : '$',
    };
  });

  return (
    <>
      <Container
        maxWidth
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%)",
          padding: "40px",
          "@media(max-width:500px)": {
            padding: "20px",
          },
        }}
      >
        <Box>
          {location.pathname === "/subscription" ? (
            <Typography
              variant="h1"
              style={{
                fontFamily: "Nunito",
                fontSize: "40px",
                fontWeight: "800",
                lineHeight: "70px",
                textAlign: "center",
              }}
            >
              Subscription plans
            </Typography>
          ) : (
            <Typography
              variant="h1"
              sx={styles.subscText}
            
            >
              Subscription plans
            </Typography>
          )}
        </Box>
        {location.pathname === "/subscription" ? (
          <Box mt={3}>
            <Typography
              variant="body1"
              style={{
                fontFamily: "Calibri",
                fontSize: "30px",
                fontWeight: "400",
                lineHeight: "30px",
                textAlign: "center",
              }}
            >
              Manage your plan and billing history
            </Typography>
          </Box>
        ) : (
          <Box mt={5}>
            <Typography
              variant="body1"
              style={{
                fontFamily: "Nunito",
                fontSize: "30px",
                fontWeight: "400",
                lineHeight: "30px",
                textAlign: "center",
              }}
            >
              Choose the plan that suits you
            </Typography>
          </Box>
        )}

        <Grid
          container
          className="cards"
          sx={{
            gap: 5,
            mt: 6,
            "@media only screen and (min-width: 900px) and (max-width: 1235px)":
              {
                gap: 14,
                marginRight: 6,
              },
          }}
        >
          {combinedPlanData.map((card) => (
            <Grid item xs={10} sm={6} md={2.6} lg={2.8} key={card.id}>
              <PlansCard
                card={card}
                location={location}
                handleSignUpTogle={handleSignUpTogle}
                openSignUp={openSignUp}
                handleSignInClose={handleSignInClose}
                openSignIn={openSignIn}
                handleSignInOpen={handleSignInOpen}
                handleForgotTogle={handleForgotTogle}
                handleOTPTogle={handleOTPTogle}
                openForgot={openForgot}
                auth={auth}
                SignInPage={SignInPage}
                SignUpPage={SignUpPage}
                ForgotPassword={ForgotPassword}
                handleForgotInOpen={handleForgotInOpen}
                handleForgotInClose={handleForgotInClose}
                openOTP={openOTP}
                VerifyOTP={VerifyOTP}
                handleOTPInClose={handleOTPInClose}
                handleSignInTogle={handleSignInTogle}
                setEmail={setEmail}
                email={email}
                openReset={openReset}
                ResetPassword={ResetPassword}
                handleResetTogle={handleResetTogle}
                handleResetInClose={handleResetInClose}
              />
    
            </Grid>
          ))}
        </Grid>
      </Container>

      {location.pathname === "/subscription" && auth?.userLoggedIn && <Billing />}
    </>
  );
};

export default Subscription;