// App.js

import React from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import MainLayout from "./layout/MainLayout";
import HowItWorks from "./pages/howitworks/HowItWorks";
import About from "./pages/about/AboutUs";
import Subscription from "./pages/subscriptionPlans";
import ContactUs from "./pages/contact/ContactUs";
import ProductList from "./pages/product/ProductList";
import Faq from "./pages/faq/Faq";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
import TermsCondition from "./pages/termsCondition/Terms&Condition";
import MyCart from "./pages/myCart";
import PaymentFail from "./pages/payment/PaymentFail";
import PaymentSuccess from "./pages/payment/PaymentSuccess";
import MyOrderDetails from "./pages/myOrderDetails";
import BuyTeddi from "./pages/buyTeddi/BuyTeddi";
import MyOrder from "./pages/myOrder/MyOrder";
import AuthGuard from "../src/components/AuthGuard";
import Cookies from "../src/pages/cookies/Cookies";
import { AuthProvider } from "../src/context/Auth";
import { Toaster } from "react-hot-toast";
import Testimonial from "./components/testimonial/Testimonial";
import SuccessSubscription from "./pages/success/SuccessSubscription";

const App = () => (
  <BrowserRouter>
  <Toaster position="top-right" />
    <AuthProvider>
      <Routes>
      {/* <Route
          path="Testimonial"
          element={<Testimonial />}
          /> */}
        <Route
          path="/"
          element={<MainLayout />}
        >
     
          <Route
            index
            element={<Home />}
          />

<Route
           path="success"
           element={<Home />}
           />


          <Route
            path="about"
            element={<About />}
          />
          <Route
            path="howitworks"
            element={<HowItWorks />}
          />
          <Route
            path="subscription"
            element={<Subscription />}
          />
          
          <Route
            path="contact"
            element={<ContactUs />}
          />
          {/* Add AuthGuard to other routes as needed */}
          <Route
            path="faq"
            element={<Faq />}
          />
        
          <Route
            path="terms-condition"
            element={<TermsCondition />}
          />
          <Route
            path="privacy-policy"
            element={<PrivacyPolicy />}
          />
            <Route
            path="cookies-policy"
            element={<Cookies/>}
          />
          <Route
            path="buy-teddi"
            element={<AuthGuard><BuyTeddi /></AuthGuard>}
          />
          <Route
            path="my-order"
            element={<AuthGuard><MyOrder /></AuthGuard>}
          />
          <Route
            path="order-details"
            element={<AuthGuard><MyOrderDetails /></AuthGuard>}
          />
          <Route
            path="my-cart"
            element={<AuthGuard><MyCart /></AuthGuard>}
          />
          <Route
            path="failure"
            element={<AuthGuard><PaymentFail /></AuthGuard>}
          />
          <Route
            path="successful"
            element={<AuthGuard><PaymentSuccess /></AuthGuard>}
          />
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
