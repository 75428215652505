import React, { useEffect, useState, } from "react";
import { Box, Grid, Typography, Button, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
import AppleIcon from "@mui/icons-material/Apple";
import GradeIcon from "@mui/icons-material/Grade";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { NavLink } from "react-router-dom";
import apiConfig from "./../../apiConfig/config";
import toast from "react-hot-toast";
import { animateScroll as scroll } from 'react-scroll';
import {
  facebookFooterIcon,
  instaFooterIcon,
  youtubeFooterIcon,
  twitterFooterIcon
} from "./../../utils"
const Footer = () => {
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState('');

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values)
    try {
      delete values.checked
      const res = await axios({
        method: "POST",
        url: apiConfig['addNewsLatter'],
        data: values
      });
      if (res.data.responseCode == 200) {
        resetForm();
        setSuccessMessage(res?.data?.responseMessage || "Your query has been submitted");
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      } else {
        console.log(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );
        setSuccessMessage("");
        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
        "Something went wrong. Please try again."
      );
      setSuccessMessage("");
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Please enter valid email.").required("Please enter email."),
  });

  const googleApp = () => {
    const link = homeContentFeature.link1;
    if (link) {
      window.open(link, '_blank');
    } else {
      console.log("Something wrong");
    }
  }

  const appleApp = () => {
    const link = homeContentFeature.link2;
    if (link) {
      window.open(link, '_blank');
    } else {
      console.log("Something wrong");
    }
  }

  const [isHome, setIsHome] = React.useState(null)
  React.useEffect(() => {

    const currentPath = window.location.pathname;
    if (currentPath == '/') {

      setIsHome(true)
    }
    else {

      setIsHome(false)
    }

  }, [window.location.pathname]);

  const [homeContentFeature, setHomeContentFeature] = useState({
    link1: '',
    link2: '',
    link3: '',
    link4: '',
    link5: '',
    link6: '',
    // Initialize with empty strings for each link type
  });

  const { link1, link2, link3, link4, link5, link6 } = homeContentFeature;

  const getStaticLink = async () => {
    try {
      const featureTypes = ["Google_Play_Link", "App_Store_Link", "Facebook_Link", "Twitter_Link", "Instagram_Link", "YouTube_Link"];
      const apiRequests = featureTypes.map(type =>
        axios.get(apiConfig.viewStaticContent, {
          params: { type }
        })
      );

      const responses = await Promise.allSettled(apiRequests);

      console.log("aqqqaaqaqqqqaaaaaqqq", responses)
      const updatedContent = responses.reduce(
        (acc, response, index) => {
          if (response.status === "fulfilled") { // Check if the request was successful
            const { data } = response.value;
            if (data.responseCode === 200) {
              acc[`link${index + 1}`] = data.result.description;
            }
          } else {
            console.error(`Request ${index + 1} failed:`, response.reason);
          }
          return acc;
        },
        { ...homeContentFeature } // Initialize with the current state
      );

      setHomeContentFeature(updatedContent);
    } catch (error) {
      console.error("Something went wrong. Please try again.", error);
    }
  };

  useEffect(() => {
    const fetchContent = async () => {
      await getStaticLink();
    };
    fetchContent();
  }, []);





  return (
    <Grid
      sx={{
        paddingTop: "3rem",
        margin: "0 3rem",
        "@media(max-width:425px)": { margin: "0 1rem" },
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "#FBC8DE",
          borderRadius: "16px",
          paddingX: "6%",
        }}
      >
        <Grid item sm={12} md={5} lg={5} sx={{ padding: "0.5rem 0 1rem 2%" }}>
          <Box sx={{ display: "flex" }}>
            <img style={{ height: "8.5rem" }} src="/logo2.png" />
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "1.6rem",
                color: "#262626",
                textAlign: "left",
                "@media(max-width:1024px)": { lineHeight: "normal" },
              }}
            >
              Subscribe To Our Newsletter!
            </Typography>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange }) => (

              <Form>
                <Box gap={2} sx={{ display: "flex", padding: "8px 0" }}>
                  <TextField
                    placeholder="Your email address"
                    size="small"
                    name="email"
                    value={values?.email}
                    onChange={handleChange}
                    error={touched.email && !!errors.email}
                    helperText={
                      <Box
                        sx={{
                          background: "transparent",
                          marginLeft: '-15px',
                          color: touched.email && errors.email ? 'red' : 'green',
                        }}
                      >
                        {touched.email && errors.email ? errors.email : successMessage}
                      </Box>
                    }
                    sx={{ width: "65%", borderRadius: "4px" }}
                    InputProps={{
                      style: {
                        color: "#636880",
                        fontSize: "14px",
                        backgroundColor: "#fff",
                        fontWeight: 400,
                        fontFamily: "Poppins",
                      },
                    }}
                  />

                  <Button
                    type="submit"

                    sx={{
                      backgroundColor: "#00C6FF",
                      color: "#fff",
                      height: "38px",
                      padding: "1px 25px",
                      textTransform: "none",
                      fontFamily: "Nunito",
                      fontWeight: 700,
                      "&:hover": {
                        backgroundColor: "#00C6FF",
                      },
                      "@media(max-width:320px)": {
                        fontSize: "12px",
                      },
                      "@media(max-width:425px)": {
                        padding: "6px 8px",
                      },
                      "@media(max-width:1024px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    Subscribe Now
                  </Button>
                </Box>
              </Form>
            )}</Formik>
        </Grid>
        <Grid
          item
          sm={12}
          md={7}
          lg={7}
          sx={{
            paddingTop: "2rem",
          }}
        >
          <Box gap={1} sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Box
              gap={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                "@media(max-width:768px)": { gap: 1 },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#262626",
                  fontFamily: "Nunito",
                  fontSize: "18px",
                  fontWeight: 500,
                  WebkitTextStroke: "0.5px",
                  "@media(max-width:425px)": { fontSize: "16px" },
                }}
              >
                Quick Links{" "}
              </Typography>
              <Typography
                component="a"
                href="/terms-condition"
                sx={{
                  textDecoration: "none",
                  color: "#575757",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  cursor: "pointer",
                  "@media(max-width:425px)": { fontSize: "13px" },
                }}
              >
                Terms & Conditions
              </Typography>
              <Typography
                component="a"
                href="/privacy-policy"
                sx={{
                  textDecoration: "none",
                  color: "#575757",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  cursor: "pointer",
                  "@media(max-width:425px)": { fontSize: "13px" },
                }}
              >
                Privacy Policy
              </Typography>
              {isHome ?
                <a style={{ textDecoration: "none" }} href="#abc"

                  to="/?scrollTo=abc"
                >
                  <Typography

                    // href="/"
                    sx={{
                      textDecoration: "none",
                      color: "#575757",
                      fontFamily: "Nunito",
                      fontSize: "16px",
                      fontWeight: 400,
                      cursor: "pointer",
                      "@media(max-width:425px)": { fontSize: "13px" },
                    }}
                  >
                    Testimonials
                  </Typography></a>
                : <Link
                  style={{ textDecoration: "none" }}
                  to="/?scrollTo=abc"
                >
                  <Typography

                    // href="/"
                    sx={{
                      textDecoration: "none",
                      color: "#575757",
                      fontFamily: "Nunito",
                      fontSize: "16px",
                      fontWeight: 400,
                      cursor: "pointer",
                      "@media(max-width:425px)": { fontSize: "13px" },
                    }}
                  >
                    Testimonials
                  </Typography></Link>}
              <Typography
                component="a"
                href="/cookies-policy"
                sx={{
                  textDecoration: "none",
                  color: "#575757",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  cursor: "pointer",
                  "@media(max-width:425px)": { fontSize: "13px" },
                }}
              >
                Cookies Policy
              </Typography>
            </Box>
            <Box
              gap={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                "@media(max-width:768px)": { gap: 1 },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#262626",
                  fontFamily: "Nunito",
                  fontSize: "18px",
                  fontWeight: 500,
                  WebkitTextStroke: "0.5px",
                  "@media(max-width:425px)": { fontSize: "16px" },
                }}
              >
                Support
              </Typography>

              <Typography
                component="a"
                href="/contact"
                sx={{
                  textDecoration: "none",
                  color: "#575757",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  cursor: "pointer",
                  "@media(max-width:425px)": { fontSize: "13px" },
                }}
              >
                Contact Us
              </Typography>
              <Typography
                component="a"
                href="/faq"
                sx={{
                  textDecoration: "none",
                  color: "#575757",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  cursor: "pointer",
                  "@media(max-width:425px)": { fontSize: "13px" },
                }}
              >
                FAQs
              </Typography>
            </Box>
            <Box
              gap={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#262626",
                  fontFamily: "Nunito",
                  fontSize: "18px",
                  fontWeight: 500,
                  WebkitTextStroke: "0.5px",
                  "@media(max-width:425px)": { fontSize: "16px" },
                }}
              >
                Follow Us
              </Typography>
              <Typography
                sx={{
                  color: "#575757",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  display: 'flex',
                  alignItems: 'center',
                  "@media(max-width:425px)": { fontSize: "13px" },
                }}
              >
                <a href={link3} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', color: '#575757', textDecoration: 'none' }}>
                  {facebookFooterIcon}&nbsp;&nbsp;Facebook
                </a>
              </Typography>
              <Typography
                sx={{
                  color: "#575757",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: 'center',
                  "@media(max-width:425px)": { fontSize: "13px" },
                }}
              >
                <a href={link4} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', color: '#575757', textDecoration: 'none' }}>
                  {twitterFooterIcon}&nbsp;&nbsp;Twitter
                </a>
              </Typography>
              <Typography
                sx={{
                  color: "#575757",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: 'center',
                  "@media(max-width:425px)": { fontSize: "13px" },
                }}
              >
                <a href={link5} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', color: '#575757', textDecoration: 'none' }}>
                  {instaFooterIcon}&nbsp;&nbsp;Instagram
                </a>
              </Typography>
              <Typography
                sx={{
                  color: "#575757",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: 'center',
                  "@media(max-width:425px)": { fontSize: "13px" },
                }}
              >
                <a href={link6} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', color: '#575757', textDecoration: 'none' }}>
                  <Box style={{
                    fontSize: "15px",
                    color: "black",
                    paddingRight: "5px",
                  }}>
                    {youtubeFooterIcon}
                  </Box>
                  YouTube
                </a>
              </Typography>
            </Box>

          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",

              "@media(max-width:1024px)": {
                marginTop: "2rem",
              },
              "@media(max-width:768px)": {
                justifyContent: "center",
                marginTop: "0",
              },
            }}
          >
            <Box sx={{ "@media(max-width:768px)": { paddingBottom: "1rem" } }}>
              <Typography
                sx={{
                  WebkitTextStroke: "0.5px",
                  fontFamily: "Roboto Condensed",
                  fontSize: "20px",
                  fontWeight: "500",
                  marginBottom: "18px"
                }}
              >
                Get the App
              </Typography>
              <Box
                gap={4}
                sx={{
                  paddingBottom: "1.5rem",
                  display: "flex",
                  "@media(max-width:425px)": { gap: 2 },
                  "@media(max-width:340px)": { gap: 1 },
                }}
              >
                <Box
                  onClick={googleApp}
                  sx={{
                    backgroundColor: "#000",
                    color: "#fff",
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    // justifyContent: "center",
                    border: "1px solid black",
                    height: "2.5rem",
                    minWidth: "10vw",
                    borderRadius: "12px",
                    padding: "3px 12px",
                    "@media(max-width:376px)": {
                      padding: "3px 8px",
                    },
                  }}
                >
                  <img
                    style={{ width: "40px", height: "38px" }}
                    src="/playstore.png"
                    alt="logo"
                  ></img>
                  <Box
                    sx={{
                      paddingLeft: "5px",
                      "@media(max-width:376px)": {
                        paddingLeft: "0",
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: "10px" }}>GET IT ON</Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        "@media(max-width:376px)": {
                          fontSize: "14px",
                        },
                        "@media(max-width:340px)": {
                          fontSize: "11px",
                        },
                      }}
                    >
                      Google Play
                    </Typography>
                  </Box>
                </Box>
                <Box
                  onClick={appleApp}
                  sx={{
                    backgroundColor: "#000",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "1px solid black",
                    height: "2.5rem",
                    minWidth: "10vw",
                    borderRadius: "12px",
                    padding: "3px 12px",
                    "@media(max-width:376px)": {
                      fontSize: "14px",
                      padding: "3px 8px",
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      color: "white",
                      "@media(max-width:376px)": {
                        padding: "0",
                      },
                    }}
                  >
                    <AppleIcon sx={{ fontSize: "2.5rem" }} />
                  </IconButton>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        "@media(max-width:340px)": { fontSize: "8px" },
                      }}
                    >
                      Download on the
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        "@media(max-width:340px)": { fontSize: "13px" },
                      }}
                    >
                      App Store
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        color="#262626"
        align="center"
        sx={{ padding: "1.5rem 0" }}
      >
        © 2024 Teddy Tale | All Right Reserved.
      </Typography>
    </Grid>
  );
};

export default Footer;
