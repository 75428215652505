import {
  Box,
  Button,
  Divider,
  Grid,
  CircularProgress ,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useCallback, useState, useContext } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { ShimmerButton } from "react-shimmer-effects";
import { AuthContext } from "../../context/Auth";
import {
  orderDelivered,
  orderCancelled,
  orderInProcess,
  orderPending,
  getAllOrders,
  apiFilters,
  convertDateTime
} from "./../../utils"

const MyOrder = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [filterParams, setfilterParams] = useState(apiFilters);
  const getOrders = useCallback(async (filterParams) => {
    setIsLoading(true)
    let result = await getAllOrders(filterParams)
    console.log("sdbhsdfb", result?.response?.data?.responseMessage)
    if (!result?.response?.data?.responseMessage) {
      setOrderData(await getAllOrders(filterParams))
    }
    setIsLoading(false)
  }, [filterParams, setIsLoading])

  useEffect(() => {
    if (auth?.isLoginSession) {
      getOrders(filterParams);
    }
  }, [auth?.isLoginSession, filterParams])
  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    setfilterParams({ ...filterParams, [name]: value });
  };
  
  const handleChangeSearch = (event) => {
    const { name, value } = event.target;
    setfilterParams({ ...filterParams, [name]: value });
  };
  
  return (
    <>
      <Grid
        container
        sx={{ padding: "0 11.5%", minHeight: "80vh"}}
      >
        <Grid item lg={12}>
          <Box
            gap={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "2rem 0 2rem 0",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#262626",
                  fontWeight: 800,
                  fontSize: "2.5rem",
                  fontFamily: "Nunito",
                  '@media(max-width:650px)': {
                    fontSize: "1.5rem"
                  }
                }}
              >
                My Orders
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControl sx={{ m: 1, minWidth: 120, '@media(max-width:600px)': { minWidth: 80 } }} size="small">
                <InputLabel id="demo-select-small-label">All</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={filterParams.deliveryStatus}
                  label="deliveryStatus"
                  name="deliveryStatus"
                  onChange={handleChangeSelect}
                >
                  <MenuItem value="">
                    None
                  </MenuItem>
                  <MenuItem value={"DELEVERED"}>Delivered</MenuItem>
                  {/* <MenuItem value={"COMPLETED"}>Completed</MenuItem> */}
                  <MenuItem value={"PENDING"}>Pending</MenuItem>
                  {/* <MenuItem value={"CANCELLED"}>Cancelled</MenuItem> */}
                </Select>
              </FormControl>
              {/* <TextField 
             
                size="small"
                placeholder="Search your orders here"
                variant="outlined"
                name="search"
                value={filterParams.search}
                onChange={handleChangeSearch}
                InputProps={{
                  endAdornment: (
                    <>
                      <img style={{ height: "30px" }} src="search.png" alt="" />
                    </>
                  ),
                  style: {}
                }}
              /> */}

            </Box>
          </Box>
        </Grid>
        <Grid item lg={12}>
          {isLoading === false && orderData?.length === 0 ? (<>
            <Grid item sm={12} xs={12} md={12}>
              <Box>
                <Typography style={{display:"flex",justifyContent:"center"}}>No orders available.</Typography>
              </Box>
            </Grid>
          </>) : isLoading === true && (<>
            <Grid item sm={12} xs={12} md={12}>
            <Box 
      sx={{ 
      display:"flex",
        justifyContent: 'center', 
        alignItems: 'center', 
      }}
    >
      <CircularProgress  color="inherit" />
    </Box>
            </Grid>
          </>)}
          {orderData?.map((item, index) => (
            <Grid item sm={12} xs={12} md={12}>
              <Box
                key={index}
                sx={{
                  marginBottom: "3rem",
                  padding: "1rem",
                  boxShadow:5,
                  borderRadius:"10px",
                  backgroundColor: "#FAFAFA",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{}}>
                  {item?.deliveryStatus === "DELEVERED" ? (
  orderDelivered
) : item?.deliveryStatus === "PENDING" ? (
  orderInProcess
) : null}             </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ padding: "0 0 10px 1rem" }}>
                      <Typography
                        sx={{
                          color: "#161e29",
                          fontSize: "18px",
                          fontWeight: 600,
                          fontFamily: "Nunito",
                        }}
                      >
                        {(
                         item?.deliveryStatus === "PENDING" ? "Delivery In Pending":
                          item?.deliveryStatus === "DELEVERED" ? "Delivered" : "Delivery Cancelled")}
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(0,0,0,0.6)",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Nunito",
                        }}
                      >
                        {convertDateTime(item?.createdAt)}
                      </Typography>
                    </Box>
                    <Box style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                      onClick={() => {
                        let details = { ...item };
                        details.status = (
                        item?.deliveryStatus === "PENDING" ? "PENDING":
                         item?.deliveryStatus === "DELEVERED" ? "DELEVERED" :
                          "CANCELLED");
                        details.image = "/teddi.png";
                        details.name = "Teddy";
                        navigate('/order-details', {

                          state: {
                            orderData: details
                          }
                        });
                      }}
                    >
                      <NavigateNextIcon
                        sx={{
                          backgroundColor: "rgba(8, 5, 21, 0.09)",
                          borderRadius: "50%",
                          cursor: "pointer",
                          color: "#686d75",
                          fontSize: "2rem",
                          padding: "5px"
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ borderColor: "#ececec" }} />
                <Box sx={{ display: "flex", padding: "10px 0" }}>
                  <Box
                    sx={{
                      border: "1px solid #d7d7d7",
                      borderRadius: "8px",
                      height: "85px",
                      width: "65px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ height: "52px", width: "50px" }}
                      src={"/teddi.png"}
                      alt=""
                    />
                  </Box>
                  <Box sx={{ paddingLeft: "1rem" }}>
                    <Typography
                      sx={{
                        color: "#161e29",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontFamily: "Nunito",
                      }}
                    >
                      {"Teddy"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(0,0,0,0.6)",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Nunito",
                      }}
                    >
                      {`Ordered ID-${item?._id}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#161e29",
                        fontSize: "16px",
                        fontWeight: 500,
                        fontFamily: "Nunito",
                      }}
                    >
                      <span>&#36;</span>
                      {item?.totalPrice}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ borderColor: "#ececec" }} />
                <Box sx={{ paddingTop: "10px" }}>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default MyOrder;
