import React from "react";
import { Box, Grid, Typography, Button, styled } from "@mui/material";
import { RiDeleteBin4Fill } from "react-icons/ri";

const StyledComponent = styled("div")({
  fontFamily: "Nunito",
  "& h2": {
    color: "#161E29",
    fontSize: "45px",
    fontStyle: "normal",
    fontFamily: "Nunito, sans-serif",
    fontWeight: 800,
  },
  "& h6": {
    fontFamily: "Nunito",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "25px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "& .MuiTypography-body2": {
    cursor: "pointer",
    fontFamily: "Nunito",
  },
});

const MyCart = () => {
  return (
    <StyledComponent>
      <Box sx={{ padding: "50px 9%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "6%",
          }}
        >
          <Typography variant="h2">Your Orders</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                borderRadius: "10px",
                padding: "0 ",
                backgroundColor: "FAFAFA",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  borderRadius: "10px",
                  padding: "0px 20px 0 0px",
                  backgroundColor: "FAFAFA",
                }}
              >
                <Grid container className="main">
                  <Grid item lg={5} md={5} sm={6} xs={12}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Box
                        sx={{
                          maxWidth: "50px",
                          padding: "10px",
                          border: "1px solid #B8B8B8",
                          borderRadius: "10px",
                        }}
                      >
                        <img src="teddi.png" style={{ width: "100%" }} alt="" />
                      </Box>
                      <Box>
                        <Typography variant="h6">Pink Teddy</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={7} md={7} sm={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 0 20px 20px",
                      }}
                    >
                      <Typography variant="h6">$100</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          maxWidth: "80px",
                          width: "100%",
                          border: "1px solid rgba(217, 217, 217, 1)",
                          padding: "10px",
                          height: "21px",
                          borderRadius: "5px",
                          justifyContent: "space-around",
                          alignItems: "center",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        <Typography variant="body2">-</Typography>
                        <Typography variant="body2">1</Typography>
                        <Typography variant="body2">+</Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          textAlign: "center",
                          borderRadius: "5px",
                          padding: "5px",
                          background: "rgba(217, 217, 217, 1)",
                          color: "#00C6FF",
                        }}
                      >
                        <RiDeleteBin4Fill />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <hr></hr>
              <Box
                sx={{
                  borderRadius: "10px",
                  padding: "0px 20px 0 0px",
                  backgroundColor: "FAFAFA",
                }}
              >
                <Grid container className="main">
                  <Grid item lg={5} md={5} sm={6} xs={12}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Box
                        sx={{
                          maxWidth: "50px",
                          padding: "10px",
                          border: "1px solid #B8B8B8",
                          borderRadius: "10px",
                        }}
                      >
                        <img src="teddi.png" style={{ width: "100%" }} alt="" />
                      </Box>
                      <Box>
                        <Typography variant="h6">Pink Teddy</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={7} md={7} sm={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 0 20px 20px",
                      }}
                    >
                      <Typography variant="h6">$100</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          maxWidth: "80px",
                          width: "100%",
                          border: "1px solid rgba(217, 217, 217, 1)",
                          padding: "10px",
                          height: "21px",
                          borderRadius: "5px",
                          justifyContent: "space-around",
                          alignItems: "center",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        <Typography variant="body2">-</Typography>
                        <Typography variant="body2">1</Typography>
                        <Typography variant="body2">+</Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          textAlign: "center",
                          borderRadius: "5px",
                          padding: "5px",
                          background: "rgba(217, 217, 217, 1)",
                          color: "#00C6FF",
                        }}
                      >
                        <RiDeleteBin4Fill />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <hr />
              <Box
                sx={{
                  borderRadius: "10px",
                  padding: "0px 20px 0 0px",
                  backgroundColor: "FAFAFA",
                }}
              >
                {/* Your grid items here */}
                <Grid container className="main">
                  <Grid item lg={5} md={5} sm={6} xs={12}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Box
                        sx={{
                          maxWidth: "50px",
                          padding: "10px",
                          border: "1px solid #B8B8B8",
                          borderRadius: "10px",
                        }}
                      >
                        <img src="teddi.png" style={{ width: "100%" }} alt="" />
                      </Box>
                      <Box>
                        <Typography variant="h6">Pink Teddy</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={7} md={7} sm={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 0 20px 20px",
                      }}
                    >
                      <Typography variant="h6">$100</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          maxWidth: "80px",
                          width: "100%",
                          border: "1px solid rgba(217, 217, 217, 1)",
                          padding: "10px",
                          height: "21px",
                          borderRadius: "5px",
                          justifyContent: "space-around",
                          alignItems: "center",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        <Typography variant="body2">-</Typography>
                        <Typography variant="body2">1</Typography>
                        <Typography variant="body2">+</Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          textAlign: "center",
                          borderRadius: "5px",
                          padding: "5px",
                          background: "rgba(217, 217, 217, 1)",
                          color: "#00C6FF",
                        }}
                      >
                        <RiDeleteBin4Fill />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {/* Repeat this structure for other items */}
              </Box>
              <hr />
              <Box
                sx={{
                  borderRadius: "10px",
                  padding: "0px 20px 0 0px",
                  backgroundColor: "FAFAFA",
                }}
              >
                {/* Your grid items here */}
                <Grid container className="main">
                  <Grid item lg={5} md={5} sm={6} xs={12}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Box
                        sx={{
                          maxWidth: "50px",
                          padding: "10px",
                          border: "1px solid #B8B8B8",
                          borderRadius: "10px",
                        }}
                      >
                        <img src="teddi.png" style={{ width: "100%" }} alt="" />
                      </Box>
                      <Box>
                        <Typography variant="h6">Pink Teddy</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={7} md={7} sm={6} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 0 20px 20px",
                      }}
                    >
                      <Typography variant="h6">$100</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          maxWidth: "80px",
                          width: "100%",
                          border: "1px solid rgba(217, 217, 217, 1)",
                          padding: "10px",
                          height: "21px",
                          borderRadius: "5px",
                          justifyContent: "space-around",
                          alignItems: "center",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        <Typography variant="body2">-</Typography>
                        <Typography variant="body2">1</Typography>
                        <Typography variant="body2">+</Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          textAlign: "center",
                          borderRadius: "5px",
                          padding: "5px",
                          background: "rgba(217, 217, 217, 1)",
                          color: "#00C6FF",
                        }}
                      >
                        <RiDeleteBin4Fill />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {/* Repeat this structure for other items */}
              </Box>
              <hr />
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box
                sx={{
                  padding: "20px",
                  border: "1px solid #B8B8B8",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography variant="h6" style={{ fontSize: "28px" }}>
                  Order Summary
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ color: "rgba(0,0,0,0.6)" }}>
                    Total Items
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: "16px" }}>
                    4
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ color: "rgba(0,0,0,0.6)" }}>
                    Items VAT
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: "16px" }}>
                    $72
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ color: "rgba(0,0,0,0.6)" }}>
                    Shipping
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: "16px" }}>
                    $50
                  </Typography>
                </Box>
                <hr />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ color: "rgba(0,0,0,0.6)" }}>
                    Total
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: "16px" }}>
                    $1050
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "6px",
                    marginTop: "10px",
                    fontSize: "20px",
                    textTransform: "none",
                    bgcolor: "#00C6FF",
                    color: "white",
                    py: "10px",
                    width: "100%",
                  }}
                >
                  Proceed to Checkout
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </StyledComponent>
  );
};

export default MyCart;
