import { Box, Typography, Button } from '@mui/material';
import React from 'react';
import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';
export default function NotFound(props) {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/");
    };
    return (
        <Page title="payment successfull!">
            <Box pt={20}>
                <Typography variant='h3' align="center">Success!</Typography>
                <Typography variant='h5' align="center">Your payment was successful!

                </Typography>
                <Box display={"flex"} justifyContent={"center"} padding={2}>
                    <Button
                        variant="container"
                        sx={{
                            color: "#fff",
                            background: "#00cafc",
                            "&:hover": {
                                color: "#000"
                            }
                        }}
                        onClick={handleClick}
                    >
                        {"click here to back to dashboard"}
                    </Button>
                </Box>
            </Box>
        </Page>
    )
}