// AuthProvider.js

import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../apiConfig/config";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Token = `${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Token;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

function checkSession() {
  return (axios.defaults.headers.common.Token) ? true : false;
}

export const AuthProvider = (props) => {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [isLoginSession, setLoginSession] = useState(checkSession());
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [searchToken, setSearchToken] = useState("");
  const [theme, setTheme] = useState(true);
  const token = window.sessionStorage.getItem("token");
  const [Role, setRole] = useState("");
  const navigate = useNavigate();  // Move useNavigate here

  const getProfileHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.profile,
      });
      if (res.data.responseCode === 200) {
       
        setUserData(res.data.result);
        setRole(res.data.result?.userType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      setSession(token)
      setLoginSession(true)
      getProfileHandler();
    }
  }, [token]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    isLoginSession,
    searchToken,
    Role,
    isLoading,
    setRole,
    setIsLoading: (data) => setIsLoading(data),
    theme,
    setTheme: (data) => setTheme(data),
    setSearchToken: (data) => setSearchToken(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    getProfileHandler: (data) => getProfileHandler(data),
    navigate,
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
};
