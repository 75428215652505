import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  IconButton
} from "@mui/material";
import { gapi } from "gapi-script";
import { socialkeys } from "./../../utils";
import GoogleLogin from "react-google-login";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import apiConfig from "./../../apiConfig/config";
import { AuthContext } from "../../context/Auth";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

function SignInPage({ open,openforgot, onClose, toggleSignup, toggleForgot,toggleSignin }) {
  const [termsChecked, setTermsChecked] = useState(false);
  const gid = socialkeys.google.clientid;
  const [isloading, setLoader] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const handleSignInClose = () => {
    onClose();
  };
  const handleBothActions = () => {
        
  };

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
  };

  const onSuccess = async (response) => {
   
    try {
      setLoader(true);
      axios({
        method: "POST",
        url: apiConfig.socialLogin,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          socialId: response.googleId,
          name: response?.profileObj?.name,
          email: response?.profileObj?.email,
          profilePic: response?.profileObj?.imageUrl,
          socialType: "google"
        },
      })
        .then((res) => {
          setLoader(false);
          window.sessionStorage.setItem("token", res?.data?.result?.token);
          auth.userLogIn(true, res?.data?.result?.token);
          auth.getProfileHandler(res?.data?.result?.token);
          auth.setRole(res?.data?.result?.userType)
          handleSignInClose()
        })
        .catch((error) => {
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      // toast.error(error?.response?.data?.responseMessage);
    }
  };

  const onFailure = (err) => { };

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: gid,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
    
  });

  const initialValues = isRememberMe
    ? {
      mobileOrUserNameOrEmail: "",
      password: "",
    }
    : {
      mobileOrUserNameOrEmail: window.localStorage.getItem("mobileOrUserNameOrEmail")
        ? window.localStorage.getItem("mobileOrUserNameOrEmail")
        : "",
      password: window.localStorage.getItem("password")
        ? window.localStorage.getItem("password")
        : "",
    };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoader(true);
      delete values.checked
      const res = await axios({
        method: "POST",
        url: apiConfig['login'],
        data: values
      });
      if (res.data.responseCode == 200) {
        resetForm();
        setLoader(false);
        window.sessionStorage.setItem("token", res?.data?.result?.token);
        auth.userLogIn(true, res?.data?.result?.token);
        auth.getProfileHandler(res?.data?.result?.token);
        auth.setRole(res?.data?.result?.userType)
        handleSignInClose()
        toast.success(res?.data?.responseMessage || "signin successfull.");
      } else {
        console.log(
          res.data?.responseMessage || "something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
        "Something went wrong. Please try again."
      );
      toast.error(error?.response?.data?.responseMessage || "failed to submit");
      setLoader(false);

    }
  };

  const validationSchema = Yup.object().shape({
    mobileOrUserNameOrEmail: Yup.string().email("Invalid email address.").required("Please enter email address."),
    password: Yup.string().required("Please enter password."),
  });

  function rememberMe() {
    if (!isRememberMe) {
      setIsRememberMe(true);
      const mobileOrUserNameOrEmail = document.getElementById("mobileOrUserNameOrEmail");
      const password = document.getElementById("password");
      window.localStorage.setItem("mobileOrUserNameOrEmail", mobileOrUserNameOrEmail?.value);
      window.localStorage.setItem("password", password?.value);
    } else {
      setIsRememberMe(false);
      window.localStorage.removeItem("mobileOrUserNameOrEmail");
      window.localStorage.removeItem("password");
    }
  }




  return (
    <Dialog   PaperProps={{
      sx: {
        borderRadius: "10px"
      }
    }} open={open} onClose={onClose}
  >
      <Box
        sx={{
          width: "400px",
          padding: "2.5rem",
          border: "1px solid #e7e7e7",
          '@media(max-width:600px)': {
            width: "330px",
            padding: "0.5rem",
          },
          '@media(max-width:380px)': {
            width: "310px",
          },
          '@media(max-width:330px)': {
            width: "262px",
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
            // borderRadius:"20px"
          }}
        >
          <img src="/newlogo.png" height="136px" width="136px" alt="logo" />
          <Typography
            sx={{
              color: "#000",
              fontSize: "20px",
              fontWeight: 700,
              fontFamily: "Nunito",
              textTransform: "capitalize",
              '@media(max-width:600px)': {
                fontSize: "16px",
              }
            }}
          >
            Hi, welcome back to teddy tale
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, handleChange, handleBlur,isValid }) => (
            <Form>
              <Box gap={2} sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  fullWidth
                  placeholder="Enter Your Email"
                  variant="outlined"
                  size="small"
                  id="mobileOrUserNameOrEmail"
                  name="mobileOrUserNameOrEmail"
                  value={values.mobileOrUserNameOrEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "Nunito",

                      height: "44px",
                      gap: "10px",
                      padding: "8px",
                      borderRadius: "5px",
                    },
                  }}
                  error={touched.mobileOrUserNameOrEmail && !!errors.mobileOrUserNameOrEmail}
                  helperText={touched.mobileOrUserNameOrEmail && errors.mobileOrUserNameOrEmail}
                  FormHelperTextProps={{
                    style: {
                      marginLeft:"0.1%"
                    },
                  }}
                />
                <TextField
                  fullWidth
                  placeholder="Enter Your Password"
                  variant="outlined"
                  size="small"
                  value={values.password}
                  id="password"
                  type={showPassword === true ? "text" : "password"}
               
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "Nunito",

                      height: "44px",
                      gap: "10px",
                      padding: "8px",
                      borderRadius: "5px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          <Box display={"flex"}>
                            {showPassword ? (
                              <AiOutlineEye
                                style={{ color: "#BCBCBC", marginRight:"12px" ,  width:"20px",height:"20px" }}
                              />
                            ) : (
                              <AiOutlineEyeInvisible
                                style={{ color: "#BCBCBC", marginRight:"12px" ,  width:"20px",height:"20px" }}
                              />
                            )}
                          </Box>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ autoComplete: "off" }}
                  name="password"
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  FormHelperTextProps={{
                    style: {
                      marginLeft:"0.1%"
                    },
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
              <Box
                maxWidth
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop:"15px"
                }}
              >
                <Box className="check">
                  <Box>
                    <Checkbox
                      color="primary"
                      style={{  transform: "scale(1.0)" ,width: "20px",
                      height: "20px",
                     
                    
                 
                      }}
                      checked={isRememberMe}
                      onClick={rememberMe}
                      disabled={isloading}
                    />

                  </Box>
                  <Box>
                    <p className="snc">
                      Remember me
                    </p>
                  </Box>
                </Box>
                <Button
                  variant="text"
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19.1px",
                    fontFamily: "Nunito",
                    textTransform: "none",
                    padding: '0',
          

                  }}
                  onClick={() => {
                    // onClose()
                   
                    onClose()
                    toggleForgot()
                  }}>
                
                  Forgot Password ?
                </Button>
              </Box>
              <Box sx={{ marginTop: "15px" }}>
                <Button
                  // onClick={handleSignInClose}
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!isValid||isloading}
                  style={{height: "44px",
                  padding: "8px",
                  gap: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#00C6FF",
                  }}
                // LinkComponent={NavLink}
                // to="/ssubscription"
                >
    {isloading ? <CircularProgress size={24} style={{ color: "white" }} /> : "SIGN IN"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        <Box className="check" sx={{ marginTop: "15px", justifyContent: "center" }}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "16.37px",
                
              }}
            >
              Do not have an account ?
              <span>
                <Button  sx={{
                fontFamily: "Nunito",
                fontSize: "13px",
                fontWeight: "Bold",
                lineHeight: "16.37px",
                color:"#00C6FF"
              }}
              variant="text" onClick={() => {
                  onClose()
                  toggleSignup()
                }}>
                  SIGN UP
                </Button>
              </span>
            </Typography>
          </Box>
        </Box>
        <Box style={{
         textAlign: "center",
         marginTop:"15px",
         fontSize:'13px'
}}>OR</Box>
        
        <Box
          gap={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
         
          }}
        >
          <GoogleLogin
            clientId={gid}
            render={(renderProps) => (
              <Button
                disabled={renderProps.disabled}
                onClick={renderProps.onClick}
                startIcon={
                  <img
                    src="/google.png"
                    alt="google login"
                    height="25"
                  />
                }
                variant="outlined"
                size="small"
                sx={{ color: '#262626', fontSize: '12.32px',lineHeight:"15.44px", fontWeight: 400, fontFamily: 'Nunito', borderColor: '#e7e7e7',   weight:"370px",
                height:"44px", textTransform: 'none' }}
              >
                Continue with Google
              </Button>
            )}
            buttonText="Continue with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
          />
        </Box>
      </Box>
   
   
   
   
    </Dialog>
  );
}

export default SignInPage;
