import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import classes from "./contact.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import apiConfig from "./../../apiConfig/config";
import toast from "react-hot-toast";

const ContactUs = () => {
  const initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
    message: "",
    checked: false,
  };
  

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values);
    try {
      delete values.checked;
      const res = await axios({
        method: "POST",
        url: apiConfig["contactUs"],
        data: values,
      });
      if (res.data.responseCode == 200) {
        resetForm();
        toast.success(
          res?.data?.responseMessage || "Your query has been submitted."
        );
      } else {
        console.log(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again."
      );
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
    .min(2, "Name must be at least 2 characters long")
    .max(60, "Name cannot exceed 60 characters")
    .required("Name is required."),
    email: Yup.string().email("Invalid email").required("Email is required."),
    mobileNumber: Yup.string()
      .matches(/^[0-9]*$/, "Mobile number must contain only digits")
      .min(8, "Mobile number must be at least 8 digits")
      .max(16, "Mobile number cannot exceed 16 digits")
      .required("Phone number is required."),
    message: Yup.string()
      .max(300, "Message cannot exceed 300 characters")
      .required("Message is required."),
    checked: Yup.boolean().oneOf([true], "Please check the box."),
  });

  const [content, setContent] = useState("");
  const getStaticContent = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.viewStaticContent,
        params: {
          type: "contactUs",
        },
      });
      if (res.data.responseCode == 200) {
        // setContent(res.description);
        console.log(res);
        setContent(res.data.result.description);
      } else {
        console.log(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again."
      );
    }
  };

  useEffect(() => {
    getStaticContent();
  }, []);
  return (
    <>
      <Grid container sx={{ padding: "0 11.5%", minHeight: "80vh" }}>
        <Box sx={{ width: "100%" }} mt={5}>
          <Grid container spacing={10}>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{
                "@media(max-width:768px)": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Box
                mb={4}
                component={"div"}
                sx={{ overflowWrap: "anywhere" }}
                dangerouslySetInnerHTML={{
                  __html: `${content}`,
                }}
              ></Box>
              <Box
                sx={{
                  width: "90%",
                  backgroundColor: "#fafafa",
                  padding: "2rem",

                  "@media(max-width:425px)": {
                    width: "95%",
                    padding: "0",
                  },
                }}
              >
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, values, handleChange }) => (
                    <Form>
                      <Field
                        as={TextField}
                        fullWidth
                        name="name"
                        placeholder="Your Name"
                        variant="outlined"
                        sx={{ marginBottom: "1rem" }}
                        onKeyDown={(e) => {
                          const isAlphabetOrSpace = /^[a-zA-Z\s]*$/.test(e.key);
                          !isAlphabetOrSpace && e.preventDefault();
                        }}
                        InputProps={{
                          inputProps: {
                            type: "text",
                            min: "0",
                            maxLength: 60,
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonOutlineOutlinedIcon
                                sx={{
                                  color: "rgba(0, 0, 0, 0.54)",
                                  fontSize: "20px",
                                }}
                              />
                            </InputAdornment>
                          ),
                          style: {
                            fontFamily: "Nunito, sans-serif",
                            fontSize: "14px",
                            fontWeight: 400,
                            background: "#fff",
                            "&:focus": {
                              backgroundColor: "#fff",
                            },
                          },
                        }}
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                      />
                      <Field
                        as={TextField}
                        fullWidth
                        name="email"
                        placeholder="Your Email"
                        variant="outlined"
                        sx={{ marginBottom: "1rem" }}
                        InputProps={{
                          inputProps: {
                            type: "text",
                            min: "0",
                            maxLength: 60,
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon
                                sx={{
                                  color: "rgba(0, 0, 0, 0.54)",
                                  fontSize: "20px",
                                }}
                              />
                            </InputAdornment>
                          ),
                          style: {
                            fontFamily: "Nunito, sans-serif",
                            fontSize: "14px",
                            background: "#fff",
                            fontWeight: 400,
                          },
                        }}
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                      />
                      <Field
                        as={TextField}
                        fullWidth
                        name="mobileNumber"
                        placeholder="Your Phone"
                        variant="outlined"
                        sx={{ marginBottom: "1rem" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneOutlinedIcon
                                sx={{
                                  color: "rgba(0, 0, 0, 0.54)",
                                  fontSize: "20px",
                                }}
                              />
                            </InputAdornment>
                          ),
                          inputProps: {
                            type: "text",
                            min: "0",
                            maxLength: 16,
                          },
                          style: {
                            fontFamily: "Nunito, sans-serif",
                            fontSize: "14px",
                            fontWeight: 400,
                            background: "#fff",
                          },
                        }}
                        error={touched.mobileNumber && !!errors.mobileNumber}
                        helperText={touched.mobileNumber && errors.mobileNumber}
                      />
                      <Field
                        as={TextField}
                        fullWidth
                        multiline
                        minRows={4}
                        name="message"
                        placeholder="Message here..."
                        variant="outlined"
                        // sx={{ marginTop: "1rem" }}
                        InputProps={{
                          inputProps: {
                            type: "text",
                            min: "0",
                            maxLength: 300,
                          },
                          style: {
                            fontFamily: "Nunito, sans-serif",
                            fontSize: "14px",
                            fontWeight: 400,
                            background: "#fff",
                          },
                        }}
                        error={touched.message && !!errors.message}
                        helperText={touched.message && errors.message}
                      />
                      <Box
                        sx={{
                          backgroundColor: "lightgrey",
                          width: "65%",
                          margin: "1rem 0",
                          borderRadius: "5px",
                          padding: "8px 10px 10px 10px",
                          "@media(max-width:1024px)": { width: "100%" },
                          "@media(max-width:768px)": { width: "70%" },
                          "@media(max-width:425px)": { width: "100%" },
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checked"
                              color="primary"
                              sx={{ marginLeft: "5px" }}
                              checked={values.checked}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              I'm not a robot
                              <img
                                src="./Recaptcha.png"
                                alt=""
                                style={{ width: "50px", paddingLeft: "3rem" }}
                              />
                            </div>
                          }
                        />
                      </Box>
                      {/* Display error message for checkbox */}
                      {errors.checked && (
                        <Typography variant="body2" color="error">
                          {errors.checked}
                        </Typography>
                      )}

                      <Button
                        type="submit"
                        size="small"
                        fullWidth
                        variant="contained"
                        sx={{ backgroundColor: "#00C6FF", padding: "10px 0" , textTransform:"none" }}
                      >
                        Send Message
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                // paddingTop: "3rem",
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Box className={classes.imgContainer}>
                <div className={classes.image} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ContactUs;
