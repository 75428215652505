import axios from "axios";
import Apiconfigs from "../config";
import { toast } from "react-hot-toast";




export const postDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PUT",
      url: Apiconfigs[endPoint],
      data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
      
    }
  } catch (error) {
    toast.error(error?.response?.data?.responseMessage)
    console.log(error);
  }
};


export const postResetHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
      
    }
  } catch (error) {
    toast.error(error?.response?.data?.responseMessage)
    console.log(error);
  }
};

export const patchDataHandler = async (endPoint, data) => {
 
  try {
    const res = await axios({
      method: "PATCH",
      url: Apiconfigs[endPoint],
      data:data,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return res;
    }
  } 
  
  catch (error) {
    if (error.response) {
      toast.error(error?.response?.data?.responseMessage)
      return error.response;
    }
    console.log(error?.response?.data?.responseMessage)
    toast.error(error?.response?.data?.responseMessage)

    console.log(error);
  }
};

export const dataPostHandler = async ({ endPoint, dataToSend, headers }) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      headers: {
        ...headers
      },
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response.status === 440) {
      return error;
    } else if (error?.response?.data?.responseCode === 409) {
      toast.error(error?.response?.data?.responseMessage);
      return error;
    }
    else if (error?.response?.data?.responseCode === 402) {
      toast.error(error?.response?.data?.responseMessage);
      return error;
    } else if (error?.response?.data?.responseCode === 404) {
      console.log("error", error);
      toast.error(error?.response?.data?.responseMessage);
      return error;
    } else {
      return error;
    }
    return error;
  }
};

export const getDataHandlerAPI = async ({ endPoint, paramsData, headers }) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        ...headers
      },
      params: paramsData
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const patchAPIHandler = async ({ endPoint, dataToSend, headers }) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: Apiconfigs[endPoint],
      headers: {
        ...headers
      },
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    } else if (error.response.status === 400) {
      toast.error(error?.response?.data?.responseMessage);
    } else {
      toast.error(error?.response?.data?.responseMessage);
      return false;
    }
  }
};

export const putAPIHandler = async ({ endPoint, dataToSend, headers }) => {
  try {
    const res = await axios({
      method: "PUT",
      url: Apiconfigs[endPoint],
      headers: {
        ...headers
      },
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const getParticularObjectDataHanndler = async ({ endPoint, _id, headers }) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${Apiconfigs[endPoint]}`,
      headers: {
        ...headers
      },
      params: _id,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};

export const deleteDataAPIHandler = async ({ endPoint, _id, headers }) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: Apiconfigs[endPoint],
      headers: {
        ...headers
      },
      params: {
        _id: _id,
      },
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const postAPIHandlerIdInPath = async ({ endPoint, _id, headers }) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        ...headers
      },
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};
