import React, { useState, useRef,useContext } from "react";
import "./index.css";
import { Box, Button, Checkbox, Dialog, TextField, InputAdornment, Typography, Container, CircularProgress} from "@mui/material";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import axios from "axios";
import { AuthContext } from "../../context/Auth";
import apiConfig from "./../../apiConfig/config";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

function SignUpPage({ toggleSignin, onClose, open }) {
  const initialValues = {
    name: "",
    email: "",
    password: "",
  };
  const [termsChecked, setTermsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isloading, setLoader] = useState(false);
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);
  const auth = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
    setErrors({ ...errors, terms: "" });
  };
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoader(true);
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
      });
      if (image !== undefined && image !== null && image !== '') {
        formData.append("uploaded_file", image);
      }
  
      const res = await axios({
        
        method: "POST",
        url: apiConfig['signup'],
        data: formData
      });
      console.log("aaaaa",res?.data?.token)
      // debugger;
      if (res.status == 200) {
        // const token = res.data.token;

      // Store the token securely (example: using localStorage)
     
      window.sessionStorage.setItem("token", res?.data?.token);
      auth.userLogIn(true, res?.data?.token);
      auth.getProfileHandler(res?.data?.token);
      auth.setRole(res?.data?.user?.userType)
        resetForm();
        setImage(null);
        setLoader(false);
        onClose();
        toast.success(res?.data?.responseMessage || "signup successfull.");
      } else {
        console.log(
          res.data?.responseMessage || "something went wrong. Please try again."
        );
        setLoader(false);
        return null;
      }
    } catch (error) {
      setLoader(false);
      console.log(
        error?.response?.data?.responseMessage ||
        "Something went wrong. Please try again."
      );
      toast.error(error?.response?.data?.responseMessage || "failed to submit");

    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
    .matches(/^.{2,}$/, 'Name must contain at least two characters.')
    .required('Please enter your name.'),
    email: Yup.string().email("Invalid email address.").required("Please enter email address."),
    password: Yup.string()
     .min(6, 'Password must be at least 6 characters long')
      .required("Please enter password."),
  });
  
  return (
    <Dialog open={open} onClose={onClose}>
      <Box className="box">
        <img src="newlogo.png" height="109.99px" width="109.99px" alt="logo" className="image" />

        <Typography style={{fontFamily: "Nunito",
fontSize: "20px",
fontWeight: "700",
lineHeight: "24.55px",
}} className="heading">Create Your Account</Typography>
        <Box className="childSection">
          <Avatar
            src={(image) ? URL.createObjectURL(image) : "profile.png"}
            style={{
              
              height: "70px",
              width: "70px",
              border: "1px solid lightGrey",
            }}
          />
          <IconButton className="iconBtn">
            <label
              style={{ marginTop: "-50px",marginLeft:"-8px" }}
            >
              <PhotoCameraIcon
                onClick={(e) => {
                  handleIconClick(e)
                }}
                style={{ cursor: "pointer", fontSize: "20px" }}
              />
            </label>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </IconButton>
        </Box>

        <Box className="content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange, isValid,handleBlur }) => (
              <Form>
                <Box padding={1}  style={{
                }}>
                  <TextField
  fullWidth
  size="small"
  name="name"
  value={values.name}
  onBlur={handleBlur}
  onChange={(event) => {
    const { value } = event.target;
    // Prevent leading spaces
    const trimmedValue = value.startsWith(' ') ? value.trimStart() : value;
    handleChange({ target: { name: 'name', value: trimmedValue } });
  }}
  error={touched.name && !!errors.name}
  helperText={touched.name && errors.name}
  FormHelperTextProps={{
    style: {
      marginLeft: "0.1%"
    },
  }}
  placeholder="Enter Your Name"
  InputProps={{
    style: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Nunito",
      height: "44px",
      gap: "10px",
      padding: "8px",
      borderRadius: "5px",
    },
  }}
/>
                </Box>
                <Box padding={1}  style={{
                
                }}>
                  <TextField
                    fullWidth
                    size="small"
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    FormHelperTextProps={{
                      style: {
                        marginLeft:"0.1%"
                      },
                    }}
                    placeholder="Enter Your Email Address"
                    onKeyDown={(e) =>
                      (e.key === "," || e.key === "~" || e.key === "`" || e.key === '\\' || e.key === "~" || e.key === "/" || e.key === ":") &&
                      e.preventDefault()
                    }
                    InputProps={{
                      style: {
                        fontSize: "14px",
                        fontWeight: 400,
                        fontFamily: "Nunito",
  
                        height: "44px",
                        gap: "10px",
                        padding: "8px",
                        borderRadius: "5px",
                      },
                    }}
                  />
                </Box>
                <Box padding={1}>
                
                  <TextField
                    fullWidth
                    size="small"
                    name="password"
                    autoComplete={false}
                    value={values.password}
                    type={showPassword === true ? "text" : "password"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    FormHelperTextProps={{
                      style: {
                        marginLeft:"0.1%"
                      },
                    }}
                    placeholder="Enter Your Password"
                    InputProps={{
                      // inputProps: {
                      //   maxLength: 30,
                      // },
                      style: {
                        fontSize: "14px",
                        fontWeight: 400,
                        fontFamily: "Nunito",
  
                        height: "44px",
                        gap: "10px",
                        padding: "8px",
                        borderRadius: "5px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Box display={"flex"} sx={{ padding: "10px" }}>
                              {showPassword ? (
                                <AiOutlineEye
                                  style={{ color: "#BCBCBC"  ,  width:"20px",height:"20px"}}
                                />
                              ) : (
                                <AiOutlineEyeInvisible
                                  style={{ color: "#BCBCBC" ,  width:"20px",height:"20px"}}
                                />
                              )}
                            </Box>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box className="check">
                  <Box>
                    <Checkbox
                      checked={termsChecked}
                      onChange={handleTermsChange}
                      color="primary"
                      sx={{ transform: "scale(1.0)" ,width: "35px",
                      height: "20px",}}
                    />
                  </Box>
                  <Box>
                    <p style={{ display: " ", alignItems: "left", fontSize: "13px" }} className="tnc">
                      By clicking you agree to our{" "}
                      <a style={{ color: "#29A0C2", textDecoration: "none" }} href="/terms-condition"  target="_blank">Terms & Conditions</a> and{" "}
                      <a style={{ color: "#29A0C2", textDecoration: "none" }} href="/privacy-policy"  target="_blank">Privacy Policy</a>
                    </p>
                    {errors.terms && (
                      <p style={{ color: "red", fontSize: "12px" }}>{errors.terms}</p>
                    )}
                  </Box>
                </Box>
              
                <Box sx={{ marginTop: "15px" ,padding:"6px"}}>
                  
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!isValid||!termsChecked|| isloading}
                  style={{height: "44px",
                  padding: "8px",
                  gap: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#00C6FF",
                  }}
               
                >
                        {isloading ? <CircularProgress size={24} style={{ color: "white" }} /> : "SIGN UP"}
                        
                </Button>
               
              </Box>
            
                {/* <Box sx={{ marginTop: "15px"}}>
                  <Button
                    fullWidth
                    type="submit"
                    sx={{
                      height: "44px",
                  padding: "8px",
                  gap: "10px",
                  borderRadius: "5px",
                  color:"white",
                  backgroundColor: "#00C6FF",
                      "&:hover": {
                        backgroundColor: "#00c6ff",
                      },
                    }}
                  >
                
                  </Button>
                </Box> */}
              </Form>)}
          </Formik>
          <Box className="">
            <Box>
              <p style={{ display: " ", alignItems: "center" }} className="tnc">
                Already have an account ?
                <Button sx={{  fontFamily: "Nunito",
                fontSize: "13px",
                fontWeight: "Bold",
                lineHeight: "16.37px",
                color:"#00C6FF"}} variant="text" onClick={() => {
                  onClose()
                  toggleSignin()
                }}>
                  Sign In
                </Button>
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default SignUpPage;
