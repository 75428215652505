import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import apiConfig from "./../../apiConfig/config";

const styles = {
  accordion: {
    boxShadow: "none",
    // borderBottom: "1px solid #e0e0e0",
    borderTop: "1px solid #e0e0e0",
  },
  accordianHeading: {
    color: "#262626",
    fontFamily: "Nunito, sans-serif",
    fontSize: "16px",
  },
  accordianText: {
    color: "#636880",
    fontFamily: "Nunito, sans-serif",
    fontSize: "14px",
    textAlign: "justify",
    margin: "1rem 0",
  },
};

const Faq = () => {
  const [expanded, setExpanded] = useState([]);
  const [content, setContent] = useState([]);
  const handleExpandIconClick = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const getStaticContent = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.faqList,
      });
      if (res.data.responseCode == 200) {
        // setContent(res.description);
        console.log(res)
        setContent(res.data.result)
      } else {
        console.log(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
        "Something went wrong. Please try again."
      );
    }
  };

  useEffect(() => {
    getStaticContent()
  }, [])

  return (
    <>
      <Grid container sx={{ padding: "0 11.5%", minHeight: "80vh" }}>
        <Grid item lg={12}>
          <Typography
            sx={{
              color: "#262626",
              fontFamily: "Nunito, sans-serif",
              fontSize: "40px",
              fontWeight: 700,
              textAlign: "center",
              margin: "2rem 0",
              "@media(max-width:600px)": { fontSize: "40px" },
              "@media(max-width:425px)": { fontSize: "32px" },
            }}
          >
            Frequently Asked Question
          </Typography>
          <Box>
            {content.map((item, index) => {
              return (
                <Accordion
                  sx={styles.accordion}
                  key={index}
                  expanded={expanded[index]}
                  onChange={() => handleExpandIconClick(index)}
                >
                  <AccordionSummary
                    expandIcon={
                      <AddIcon
                        sx={{
                          color: "#00C6FF",
                          cursor: "pointer",
                          fontSize: "15px",
                          transform: expanded[index] ? "rotate(45deg)" : "none",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    }
                    sx={{
                      borderBottom: expanded[index]
                        ? "1px solid #e0e0e0"
                        : "none",
                      overflowWrap: "anywhere"
                    }}

                  >
                    <Typography sx={styles.accordianHeading}>
                      {item?.question ? item?.question : "--"}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ overflowWrap: "anywhere", padding:"30px"}}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.answer,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Faq;
