import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import PDF from "../../../src/components/assets/pdf.png";
import moment from "moment";
import axios from "axios";
import Apiconfigs from "../../apiConfig/config";
import toast from "react-hot-toast";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../../context/Auth";
import html2pdf from "html2pdf.js";

const styles = {
  MuiTypography: {
    root: {
      fontFamily: "Nunito",
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "40.92px",
      textAlign: "left",
    },
    body1: {
      fontFamily: "Nunito",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "21.82px",
      textAlign: "left",
    },
  },
  MuiTableHead: {
    root: {
      backgroundColor: "#4D4F50",
    },
  },
  MuiTableCell: {
    root: {
      color: "white",
      fontFamily: "Nunito",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "21.82px",
    },
  },
  MuiTableCells: {
    root: {
      fontFamily: "Nunito",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "21.82px",
    },
  },
  MuiButtonBase: {
    root: {
      textTransform: "none",
      backgroundColor: "#00C6FF",
      boxShadow: "none",
      fontFamily: "Nunito",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "27px",
    },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${theme.breakpoints.up("sm")}`]: {
    padding: "8px",
    "&:last-child": {
      paddingRight: "8px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Billing = () => {
  const [billingData, setBillingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const auth = useContext(AuthContext);
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: 1,
    limit: 10,
    type: "SUBSCRIPTION",
  });

  const PageChange = (event, value) => {
    setFilter({ ...filter, page: value });
  };

  const fetchBillingData = async () => {
    setIsLoading(true);

    try {
      const response = await axios({
        method: "POST",
        url: Apiconfigs["listTransaction"],
        data: {
          userId: auth?.userData?._id,

          ...filter,
        },
      });
      if (response?.data?.responseCode === 200) {
        setBillingData(response?.data?.result?.docs);
        setTotalPages(response?.data?.result?.pages);
      } else {
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async (invoiceId) => {
    try {
      const response = await axios({
        method: "POST",
        url: Apiconfigs["getInvoice"],
        data: {
          userId: auth?.userData?._id,
          tranxId: invoiceId,
        },
      });
      if (response.status === 200) {
        const element = document.createElement("div");
        element.innerHTML = response.data;
        const opt = {
          margin: 1,
          filename: `invoice_${invoiceId}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf().from(element).set(opt).save();
      } else {
        toast.error("Failed to download invoice");
      }
    } catch (error) {
      console.error("Error downloading invoice:", error);
      toast.error("Error downloading invoice");
    }
  };

  useEffect(() => {
    if (auth?.userData?._id) {
      fetchBillingData();
    }
  }, [auth, filter.page]);

  return (
    <Container>
      {billingData.length > 0 && (
        <>
          <Box mt={3}>
            <Typography sx={styles.MuiTypography.root} variant="h2">
              Billing History
            </Typography>
            <Typography sx={styles.MuiTypography.body1} variant="body1">
              Download your previous plan receipts and details.
            </Typography>
          </Box>
          <Box mt={4}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="customized table">
                <TableHead sx={styles.MuiTableHead.root}>
                  <TableRow>
                    {["Invoice", "Billing Date", "Amount", "Plan", ""].map(
                      (header, index) => (
                        <StyledTableCell
                          sx={styles.MuiTableCell.root}
                          key={index}
                          align="center"
                        >
                          {header}
                        </StyledTableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billingData?.map((billing, index) => (
                    <StyledTableRow
                      key={index}
                      sx={index % 2 === 0 ? { backgroundColor: "#F7F7F7" } : {}}
                    >
                      <StyledTableCell
                        sx={styles.MuiTableCells.root}
                        align="center"
                      >
                        <img
                          style={{ marginRight: "8px" }}
                          src={PDF}
                          alt="PDF"
                        />{" "}
                        Invoice #00
                        {(filter.page - 1) * filter.limit + index + 1} -{" "}
                        {moment(billing?.updatedAt ?? "--").format("D/MM") ??
                          "--"}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={styles.MuiTableCells.root}
                        align="center"
                      >
                        {moment(billing?.updatedAt ?? "--").format(
                          "D/MM/YYYY, h:mm a"
                        ) ?? "--"}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={styles.MuiTableCells.root}
                        align="center"
                        style={{ color: "#0856CC" }}
                      >
                        ${billing.amount}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={styles.MuiTableCells.root}
                        align="center"
                      >
                        {billing?.subscriptionPlanId?.planName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          sx={styles.MuiButtonBase.root}
                          variant="contained"
                          color="primary"
                          onClick={() => handleDownload(billing.tranxId)}
                        >
                          Download
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={1} display="flex" justifyContent="end">
              <Pagination
                count={totalPages}
                page={filter.page}
                onChange={PageChange}
                color="warning"
                shape="rounded"
              />
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default Billing;
