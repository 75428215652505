import React from "react";
import { Box, Typography, Grid } from "@mui/material";

const styles = {
  textStyle: {
    color: "#636880",
    fontFamily: "Nunito, sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    textAlign: "justify",
    lineHeight: "20px",
    margin: "1rem 0",
  },
};

const Cookies = () => {
  return (
    <>
      <Grid container sx={{  padding: "0 11.5%", minHeight: "80vh" }}>
        <Box>
          <Typography
            sx={{
              color: "#262626",
              fontFamily: "Nunito, sans-serif",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 800,
              textAlign: "center",
              margin: "1rem 0",
              "@media(max-width:600px)": { fontSize: "40px" },
            }}
          >
            Our Cookies Policy
          </Typography>
          <Box>
            <Typography sx={styles.textStyle}>
              These Terms and Conditions (“Terms”, “Terms and Conditions”)
              govern your relationship with Fululizo mobile applications and web
              panels (the “Service”) operated by Integral Media Limited (“us”,
              “we”, or “our”).
            </Typography>
            <Typography sx={styles.textStyle}>
              Please read these Terms and Conditions carefully before using our
              Fululizo mobile applications and web panels (the “Service”). Your
              access to and use of the Service is conditioned on your acceptance
              of and compliance with these Terms. These Terms apply to all
              visitors, users and others who access or use the Service.
            </Typography>
            <Typography sx={styles.textStyle}>
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the terms then you may not
              access the Service.
              <Typography
                sx={{
                  color: "#636880",
                  fontFamily: "Nunito, sans-serif",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                Content
              </Typography>
            </Typography>
            <Typography sx={styles.textStyle}>
              Our Service allows you to post, link, store, share and otherwise
              make available certain information, text, graphics, videos, or
              other material (“Content”). You are responsible for the Content
              that you post to the Service, including its legality, reliability,
              and appropriateness. By posting Content to the Service, you grant
              us the right and license to use, modify, publicly perform,
              publicly display, reproduce, and distribute such Content on and
              through the Service. You retain any and all of your rights to any
              Content you submit, post or display on or through the Service and
              you are responsible for protecting those rights. You agree that
              this license includes the right for us to make your Content
              available to other users of the Service, who may also use your
              Content subject to these Terms.
            </Typography>
            <Typography sx={styles.textStyle}>
              You represent and warrant that: (i) the Content is yours (you own
              it) or you have the right to use it and grant us the rights and
              license as provided in these Terms, and (ii) the posting of your
              Content on or through the Service does not violate the privacy
              rights, publicity rights, copyrights, contract rights or any other
              rights of any person.
              <Typography
                sx={{
                  color: "#636880",
                  fontFamily: "Nunito, sans-serif",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                Accounts
              </Typography>
            </Typography>
            <Typography sx={styles.textStyle}>
              When you create an account with us, you must provide us
              information that is accurate, complete, and current at all times.
              Failure to do so constitutes a breach of the Terms, which may
              result in immediate termination of your account on our Service.
              <Typography
                sx={{
                  color: "#636880",
                  fontFamily: "Nunito, sans-serif",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                You are responsible for safeguarding the password that you use
                to access the Service and for any activities or actions under
                your password, whether your password is with our Service or a
                third-party service.
              </Typography>
            </Typography>

            <Typography sx={styles.textStyle}>
              You agree not to disclose your password to any third party. You
              must notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.
            </Typography>
            <Typography sx={styles.textStyle}>
              <Typography
                sx={{
                  color: "#636880",
                  fontFamily: "Nunito, sans-serif",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                Availability, Errors and Inaccuracies
              </Typography>
              We are constantly updating our offerings of products and services
              on the Service. The products or services available on our Service
              may be mispriced, described inaccurately, or unavailable, and we
              may experience delays in updating information on the Service and
              in our advertising on other web sites. We cannot and do not
              guarantee the accuracy or completeness of any information,
              including prices, product images, specifications, availability,
              and services. We reserve the right to change or update information
              and to correct errors,inaccuracies, or omissions at any time
              without prior notice. Purchases
            </Typography>

            <Typography
              sx={{
                color: "#636880",
                fontFamily: "Nunito, sans-serif",
                fontSize: "15px",
                fontWeight: 400,
                marginTop: "1rem",
              }}
            >
              If you wish to purchase any product or service made available
              through the Service (“Purchase”), you may be asked to supply
              certain information relevant to your Purchase including,
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Cookies;
