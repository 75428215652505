import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { Link, NavLink } from "react-router-dom";
import classes from "./style.module.css";
import SignInPage from "../../pages/signIn";
import SignUpPage from "../../pages/signUp";
import ForgotPassword from "../../pages/forgotPassword";
import VerifyOTP from "../../pages/verifyOTP";
import { AuthContext } from "../../context/Auth";
import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import ResetPassword from "../../pages/resetPassword/ResetPassword";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import {CircularProgress}  from '@mui/material';

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openSignIn, setOpenSignIn] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openForgot, setOpenForgot] = React.useState(false);
  const [openOTP, setOpenOTP] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const pages = [
    {
      to: "/about",
      name: "about us",
    },

    {
      to: "/contact",
      name: "contact us",
    },
    {
      to: "/faq",
      name: "fAQs",
    },
    {
      to: "/howitworks",
      name: "how it works",
    },
  ];
  if (auth?.userLoggedIn) {
    pages.push({
      to: "/my-order",
      name: "my orders",
    });
    pages.push({
      to: "/subscription",
      name: "subscriptions",
    });
  }
  const handleSignInOpen = () => {
    setOpenSignIn(true);
  };
  const handleForgotInOpen = () => {
    setOpenForgot(true);
  };

  const handleLogout = () => {
    setIsLoading(true);
    setTimeout(() => {
      sessionStorage.clear();
      window.location.reload();
    }, 1000); // Adding a delay to simulate loading state
  };

  const handleSignInToggle = () => {
    setOpenSignIn(!openSignIn);
  };
  const handleForgotInClose = () => {
    setOpenForgot(false);
  };
  const handleSignUpTogle = () => {
    setOpenSignUp(!openSignUp);
  };

  const handleForgotTogle = () => {
    setOpenForgot(!openForgot);
  };
  const handleOTPTogle = () => {
    setOpenOTP(!openOTP);
  };
  const handleOTPInClose = () => {
    setOpenOTP(false);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleResetTogle = () => {
    setOpenReset(!openReset);
  };
  const handleResetInClose = () => {
    setOpenReset(false);
  };

  const menuPaperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "220px",
    marginTop: "10px",
    height: "150px",
    borderRadius: "10px",
    //boxShadow: "0px 4.8px 14.41px 0px #00000033",
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 370,
    height: 230,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "10px",
    boxShadow: 24,
    p: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <>
      {/* <Container maxWidth="xl" position="static"> */}
      <Grid
        Container
        sx={{
          backgroundColor: "#fff",
          paddingX: "11.5%",
        }}
      >
        <Toolbar disableGutters>
          <Link to={"/"}>
            <img src="/newlogo.png" height="70px" width="70px" alt="logo" />
          </Link>
          {/* </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                paddingLeft: "20px",
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <NavLink
                    to={page.to}
                    className={({ isActive }) =>
                      isActive ? classes.activeLink : ""
                    }
                    textAlign="center"
                    style={{
                      margin: "16px 20px",
                      color: "#000048",
                      fontFamily: "Nunito, sans-serif",
                      fontWeight: 410,
                      fontSize: "14px",
                      textDecoration: "none",
                      textTransform: "capitalize",
                    }}
                  >
                    {page.name}
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            {pages.map((page) => (
              <NavLink
                to={page.to}
                className={({ isActive }) =>
                  isActive ? classes.activeLink : ""
                }
                key={page.name}
                onClick={handleCloseNavMenu}
                style={{
                  margin: "16px 16px",
                  color: "#000048",
                  display: "block",
                  fontFamily: "Nunito",
                  fontWeight: "600",
                  fontSize: "14px",
                  textDecoration: "none",
                  textTransform: "capitalize",
                  lineHeight: "21.82px",
                }}
              >
                {page.name}
              </NavLink>
            ))}
          </Box>
          <Box display={"flex"} flexWrap={"wrap"}>
            <SignInPage
              open={openSignIn}
              toggleSignup={handleSignUpTogle}
              onClose={handleSignInToggle}
              toggleForgot={handleForgotTogle}
            />
            <SignUpPage
              open={openSignUp}
              toggleSignin={handleSignInToggle}
              onClose={handleSignUpTogle}
            />
            <ForgotPassword
              open={openForgot}
              toggleForgot={handleForgotTogle}
              toggleOTP={handleOTPTogle}
              setEmail={setEmail}
              onClose={handleForgotInClose}
            />
            <VerifyOTP
              open={openOTP}
              onClose={handleOTPInClose}
              email={email}
              toggleReset={handleResetTogle}
            />
            <ResetPassword
              open={openReset}
              onClose={handleResetInClose}
              email={email}
            />

            {auth?.userLoggedIn ? (
              <>
                <Box
                  mt={2}
                  mb={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "@media(max-width:768px)": {
                      display: "none",
                      justifyContent: "center",
                    },
                  }}
                >
                  {/* &nbsp;&nbsp; */}
                  {/* <Avatar
                    src={auth?.userData?.profilePic}
                    alt={auth?.userData?.name}
                    style={{
                      height: "40px",
                      marginRight: "10px",
                      width: "40px",
                      border: "1px solid lightGrey",
                    }}
                  /> */}

                  <Avatar
                    src={auth?.userData?.profilePic}
                    alt={auth?.userData?.name}
                    style={{
                      height: "40px",
                      marginRight: "10px",
                      width: "40px",
                      border: "1px solid lightGrey",
                      cursor: "pointer",
                    }}
                    onClick={handleMenuOpen}
                  />

                  <p
                    style={{
                      // whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "auto",
                      fontFamily: "Nunito",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "19.1px",
                    }}
                  >
                    {auth?.userData?.name}
                  </p>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      style: menuPaperStyle,
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        window.location.href =
                          "https://billing.stripe.com/p/login/test_7sIbMkgnHfJF1PieUU";
                        handleMenuClose();
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        width: "180px",
                        minHeight: "34px",
                        padding: "5px",
                        textTransform: "capitalize",
                        backgroundColor: "transparent !important",
                        "&:hover": {
                          borderRadius: "10px",
                          backgroundColor: "#fff",

                          color: "#00cafc",
                          // boxShadow: "0px 4.8px 14.41px 0px #00000033",
                          // background:
                          //   "linear-gradient(white, white) padding-box, linear-gradient(140.68deg, #e15499 9.76%, rgba(37, 148, 219, 0.46) 52.53%) border-box",
                          // border: "1px solid transparent",
                        },
                      }}
                    >
                      <AdminPanelSettingsIcon
                        sx={{
                          color: "#00cafc",
                          marginRight: "6px",
                          fontSize: 30,
                        }}
                      />
                      <Typography sx={{ fontWeight: "600", marginTop: "3%" }}>
                        Customer portal
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleMenuClose();
                        // handleLogout();
                        handleOpen();
                      }}
                      size="small"
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        width: "180px",
                        borderRadius: "8px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        marginTop: "10px",

                        "&:hover": {
                          borderRadius: "10px",
                          backgroundColor: "#fff",

                          color: "red",
                          // boxShadow: "0px 4.8px 14.41px 0px #00000033",
                          // background:
                          //   "linear-gradient(white, white) padding-box, linear-gradient(140.68deg, #e15499 9.76%, rgba(37, 148, 219, 0.46) 52.53%) border-box",
                          // border: "1px solid transparent",
                        },
                      }}
                    >
                      <LogoutIcon
                        sx={{
                          marginLeft: "-5%",
                          color: "red",
                          paddingRight: "5.5%",
                        }}
                      />
                      <Typography sx={{ fontWeight: "600" }}>Logout</Typography>{" "}
                      {/* Adjust margin as needed */}
                    </MenuItem>
                  </Menu>
                </Box>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="logout-modal-title"
                  aria-describedby="logout-modal-description"
                >
                  <Box sx={style}>
                    <img
                      src="/logoutimg.png"
                      alt="Logout"
                      style={{ width: "20%", height: "50%" }}
                    />
                    <Typography
                      id="logout-modal-description"
                      sx={{ mt: 2, fontSize: "24px", fontWeight: "600" }}
                    >
                      Logout?
                    </Typography>
                    <Typography
                      id="logout-modal-description"
                      sx={{ mt: 2, fontSize: "18px", fontWeight: "400" }}
                    >
                      Are you sure you want to logout?
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                        mt: 2,
                      }}
                    >
                      <Button
                        onClick={handleLogout}
                        sx={{
                          width: "150px",
                          borderColor: "#ff8080",
                          background: "rgb(185, 9, 9)",
                          boxShadow: 5,
                          borderRadius: "10px",
                          textTransform: "none",
                          fontSize: "18px",
                          fontWeight: "600",
                          "&:hover": {
                            background: "rgb(138, 11, 11)", // Change background color on hover
                          },
                        }}
                        fullWidth
                        variant="contained"
                      >
                        {isLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Logout"
                        )}
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          padding: "2px",
                          width: "150px",
                          boxShadow: 5,
                          borderRadius: "10px",
                          background: "#04b2e2",
                          textTransform: "none",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      {/* <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                      </Button> */}
                    </Box>
                  </Box>
                </Modal>
              </>
            ) : (
              <>
                <Button
                  onClick={handleSignInOpen}
                  size="small"
                  sx={{
                    height: "40px",
                    width: "112px",
                    backgroundColor: " #00C6FF",
                    border: "1px solid lighyellow",
                    borderRadius: "5px",
                    textTransform: "capitalize !important",
                  }}
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    marginRight: "-25px",
                  }}
                  fullWidth
                  variant="contained"
                >
                  Sign In
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Grid>
      {/* </Container> */}
    </>
  );
}
export default Header;
