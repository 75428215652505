import React, { useEffect, useState, useRef, useContext } from "react";
import AppleIcon from "@mui/icons-material/Apple";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  Typography,
  CardActions,
  CardContent,
  Card,
  Button,
} from "@mui/material";
import Testimonial from "../../components/testimonial/Testimonial";
import classes from "./index.module.css";
import {
  ShimmerBadge,
  ShimmerThumbnail,
  ShimmerSimpleGallery,
  ShimmerPostItem,
} from "react-shimmer-effects";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Subscription from "../subscriptionPlans";
import axios from "axios";
import apiConfig from "./../../apiConfig/config";
import { AuthContext } from "../../context/Auth";
import { testimonialLayer1, testimonialLayer2 } from "./../../utils";
import { animateScroll as scroll } from "react-scroll";
import { getAuthToken } from "../../utils/index";
import SignInPage from "../signIn";
import SignUpPage from "../signUp";
import ForgotPassword from "../forgotPassword";
import VerifyOTP from "../verifyOTP";
import ResetPassword from "../resetPassword/ResetPassword";
import { loadStripe } from "@stripe/stripe-js";
import { NavLink } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { useLocation } from "react-router-dom";
import HomeVideo from "./HomeVideo";
import SuccessSubscription from "../success/SuccessSubscription";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const authToken = getAuthToken();
const styles = {
  capsuleBack: {
    background: 'url("/capsule.png")',
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "100px",
    textTransform: "uppercase",
    WebkitTextStroke: "0.5px",
    fontSize: "1rem",
    "@media(max-width:340px)": {
      fontSize: "0.8rem",
    },
    width: "260px",
    "@media (min-width: 1024px) and (max-width: 1416px)": {
      width: "195px",
    },
    "@media(max-width:550px)": {
      height: "75px",
      width: "172px",
    },
    "@media(max-width:380px)": {
      height: "65px",
      width: "152px",
    },
    "@media(max-width:340px)": {
      height: "55px",
      width: "128px",
    },
  },
};

export default function Home() {
  const aref = useRef(null);
  const [openSignIn, setOpenSignIn] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openForgot, setOpenForgot] = React.useState(false);
  const [openOTP, setOpenOTP] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const [email, setEmail] = useState("");
  const auth = useContext(AuthContext);
  const [productData, setProductData] = useState(null);
  const [price, setPrice] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [sid,setSid]= useState(false);
  const location = useLocation();

  const handleSignInOpen = () => {
    setOpenSignIn(true);
  };

  const handleForgotInOpen = () => {
    setOpenForgot(true);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const handleSignInToggle = () => {
    setOpenSignIn(!openSignIn);
  };
  const handleForgotInClose = () => {
    setOpenForgot(false);
  };
  const handleSignUpTogle = () => {
    setOpenSignUp(!openSignUp);
  };

  const handleForgotTogle = () => {
    setOpenForgot(!openForgot);
  };
  const handleOTPTogle = () => {
    setOpenOTP(!openOTP);
  };
  const handleOTPInClose = () => {
    setOpenOTP(false);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleResetTogle = () => {
    setOpenReset(!openReset);
  };
  const handleResetInClose = () => {
    setOpenReset(false);
  };

  const [urlp, setUrlp] = useState();

  const getProductData = async () => {
    try {
      setLoader(true);
      const res = await axios({
        method: "GET",
        url: apiConfig.stripeProducts,
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        setLoader(false);
        const data = res.data.data;
        const productId = "prod_QAEmNgBPohCXWV";
        const product = data.find((item) => item.id === productId);

        if (product) {
          const formattedProduct = {
            id: product.id,
            description: product.description,
            name: product.name,
            features: product.features
              ? product.features.map((feature) => feature.name)
              : [],
            imageUrl: product.images ? product.images[0] : null,
            defaultPriceId: product.default_price,
          };
          setProductData(formattedProduct);
        } else {
          setProductData(null);
        }
      } else {
        setProductData(null);
      }
    } catch (error) {
      console.error("Error fetching Stripe products:", error);
      setProductData(null);
    }
  };

  // const checkout = async () => {
  //   try {
  //     const res = await axios({
  //       method: 'POST',
  //       url: apiConfig.webhookHandler,
  //     })
  //     if (res.status === 200) {
  //       return res.data;
  //     } else {
  //       return null;
  //     }
  //   }  catch (error) {
  //     console.error('Error fetching Stripe price:', error);
  //     return null;
  //   }
  // }
  const googleApp = () => {
    const link = staticLink.link1;
    if (link) {
      window.open(link, "_blank");
    } else {
      console.log("Something wrong");
    }
  };
  const appleApp = () => {
    const link = staticLink.link2;
    if (link) {
      window.open(link, "_blank");
    } else {
      console.log("Something wrong");
    }
  };
  const getPriceData = async (priceId) => {
    try {
      const res = await axios({
        method: "GET",
        url: `${apiConfig.stripeProductsPrices}/${priceId}`,
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        return res.data.unit_amount / 100; // Assuming the amount is in cents, convert to dollars
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching Stripe price:", error);
      return null;
    }
  };

  // const handleClickPremium = () => {
  //   const url="https://buy.stripe.com/test_fZedSB97d7bn94QeUY";
  //   if(url){
  //     window.location.href = url;
  //   }else{
  //     console.error("Invalid plan type");
  //   }
  // };
  const handleClickPremium = async () => {
    try {
      const response = await axios.post(apiConfig.createCheckoutSession, {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          shipping_address: {
            line1: "123 Shipping St",
            city: "Shipping City",
            state: "Shipping State",
            postal_code: "12345",
            country: "US", // Example country (will be ignored due to '*')
          },
        }),
      });

      const session = response.data;

      const stripe = await stripePromise;
      console.log(session.id);
   
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error("Error redirecting to checkout:", error);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };
  const handleGetStarted = () => {

    if (auth?.userLoggedIn) {
      handleClickPremium();
    } else {
      setSid(true);
      handleSignInOpen();
      
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getProductData();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (productData) {
      const fetchPriceData = async () => {
        const unitAmount = await getPriceData(productData.defaultPriceId);
        // setProductData((prevData) => ({
        //   ...prevData,
        //   unitAmount,
        // }));
        setPrice(unitAmount);
        // price && setLoader(false);
      };

      fetchPriceData();
    }
  }, [productData]);

  useEffect(() => {
    if (auth?.userLoggedIn && sid==true) {
      handleClickPremium();
      setSid(false);
    }

  }, [auth?.userLoggedIn]);

  useEffect(() => {
    // Extract the query parameter from the URL

    const urlParams = new URLSearchParams(window.location.search);
    const scrollTo = urlParams.get("scrollTo");

    // Scroll to the specified component if scrollTo parameter is present
    if (scrollTo) {
      setTimeout(() => {
        aref.current.click();
      }, 100);
    }
  }, []);
  // const slideRefs = useRef([]);
  useEffect(() => {
    const loadStripeScript = () => {
      // Check if the Stripe Buy Button script is already loaded
      if (!window.StripeBuyButton) {
        // If not loaded, create a script element and load the Stripe Buy Button script
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/buy-button.js";
        script.async = true;

        // Add an event listener to inject the Buy Button component once the script is loaded
        script.onload = () => {
          injectBuyButtonComponent();
        };

        // Append the script to the document body
        document.body.appendChild(script);

        // Clean up function to remove the script when the component is unmounted
        return () => {
          document.body.removeChild(script);
        };
      } else {
        // If the script is already loaded, inject the Buy Button component directly
        injectBuyButtonComponent();
      }
    };

    const injectBuyButtonComponent = () => {
      // Create the stripe-buy-button element
      const stripeBuyButton = document.createElement("stripe-buy-button");
      stripeBuyButton.setAttribute(
        "buy-button-id",
        "buy_btn_1PJuKuGl0ifWPp5x8lq25Ovq"
      );
      stripeBuyButton.setAttribute(
        "publishable-key",
        process.env.REACT_APP_STRIPE_KEY
      );

      // Append the stripe-buy-button element to the container
      const container = document.getElementById("buy-button");
      if (container && !container.firstChild) {
        container.appendChild(stripeBuyButton);
      }
    };

    // Load the Stripe script and inject the Buy Button component
    loadStripeScript();
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const scrollTo = searchParams.get("scrollTo");

    if (scrollTo === "teddySection") {
      scroller.scrollTo("teddySection", {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [homeContent, setHomeContent] = useState([]);
  const [homeContentFeature, setHomeContentFeature] = useState([]);
  const sliderRef = useRef();
  const NextArrowIcon = (
    <svg
      width="27"
      height="49"
      viewBox="0 0 27 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4324 26.9842L2.58694 48.5985C2.29063 48.8677 1.9185 49 1.54715 49C1.12551 49 0.706184 48.8283 0.400593 48.4917C-0.172684 47.8589 -0.12549 46.8802 0.508131 46.3069L23.4407 25.5189L2.65347 2.58551C2.07942 1.95266 2.12738 0.97399 2.76101 0.400714C3.39308 -0.173336 4.37175 -0.124596 4.9458 0.508251L26.5609 24.3545C26.9005 24.7298 27.0173 25.2257 26.9284 25.6875C26.974 26.1579 26.81 26.643 26.4324 26.9842Z"
        fill="black"
      />
    </svg>
  );

  const PrevArrowIcon = (
    <svg
      width="27"
      height="49"
      viewBox="0 0 27 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.524589 26.9842L24.3701 48.5985C24.6664 48.8677 25.0385 49 25.4099 49C25.8315 49 26.2508 48.8283 26.5564 48.4917C27.1297 47.8589 27.0825 46.8802 26.4489 46.3069L3.5163 25.5189L24.3036 2.58551C24.8776 1.95266 24.8296 0.97399 24.196 0.400714C23.564 -0.173336 22.5853 -0.124596 22.0112 0.508251L0.396165 24.3545C0.0565317 24.7298 -0.0602915 25.2257 0.0286785 25.6875C-0.016967 26.1579 0.147046 26.643 0.524589 26.9842Z"
        fill="black"
      />
    </svg>
  );
  const CustomNextArrow = (props) => {
    const { onClick, currentSlide, slideCount } = props;
    return (
      <Box
        className="slick-arrow custom-next-arrow"
        onClick={onClick}
        style={{
          zIndex: 1,
          position: "absolute",
          top: "50%",
          right: "0px",
          display: "block",
          "-webkit-transform": "translate(0, -50%)",
          "-ms-transform": "translate(0, -50%)",
          transform: "translate(0, -50%)",
          display: currentSlide === slideCount - 1 ? "none" : "block",
        }}
      >
        {NextArrowIcon}
      </Box>
    );
  };

  const CustomPrevArrow = (props) => {
    const { onClick, currentSlide, slideCount } = props;
    return (
      <Box
        className="slick-arrow custom-prev-arrow"
        onClick={onClick}
        style={{
          zIndex: 1,
          position: "absolute",
          top: "50%",
          display: "block",
          "-webkit-transform": "translate(0, -50%)",
          "-ms-transform": "translate(0, -50%)",
          transform: "translate(0, -50%)",
          display: currentSlide === 0 ? "none" : "block",
        }}
      >
        {PrevArrowIcon}
      </Box>
    );
  };
  var settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "14px",
    slidesToShow: 3,
    speed: 1000,
    dots: false,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          centerMode: true,
          infinite: true,
          autoplay: false,
          autoplaySpeed: 1500,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          autoplay: false,
          autoplaySpeed: 1500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          autoplay: false,
          autoplaySpeed: 1500,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          autoplay: false,
          autoplaySpeed: 1500,
        },
      },
    ],
    nextArrow: <CustomNextArrow currentSlide={currentSlide} />, // Custom next arrow component
    prevArrow: <CustomPrevArrow currentSlide={currentSlide} />,
  };

  const getStaticContent = async () => {
    try {
      const apiRequests = [
        axios.get(apiConfig.viewStaticContent, {
          params: { type: "HOME_SECTION_1" },
        }),
        axios.get(apiConfig.viewStaticContent, {
          params: { type: "HOME_SECTION_2" },
        }),
        axios.get(apiConfig.viewStaticContent, {
          params: { type: "HOME_SECTION_3" },
        }),
        axios.post(apiConfig.listTestmonial),
      ];

      const responses = await Promise.allSettled(apiRequests);

      const updatedContent = responses.reduce(
        (acc, response, index) => {
          if (response.status === "fulfilled") {
            const { data } = response.value;
            if (data.responseCode === 200) {
              acc[`section${index + 1}`] = data.result;
            }
          } else {
            console.error(`Request ${index + 1} failed:`, response.reason);
          }
          return acc;
        },
        { ...homeContent }
      );

      setHomeContent(updatedContent);
    } catch (error) {
      console.error("Something went wrong. Please try again.", error);
    }
  };

  useEffect(() => {
    getStaticContent();
  }, []);

  const getStaticFeatureContent = async () => {
    try {
      const featureTypes = [
        "FEATURE_1",
        "FEATURE_2",
        "FEATURE_3",
        "FEATURE_4",
        "FEATURE_5",
        "Feature_6",
      ];
      const apiRequests = featureTypes.map((type) =>
        axios.get(apiConfig.viewStaticContent, {
          params: { type },
        })
      );

      const responses = await Promise.allSettled(apiRequests);

      const updatedContent = responses.reduce(
        (acc, response, index) => {
          if (response.status === "fulfilled") {
            // Check if the request was successful
            const { data } = response.value;
            if (data.responseCode === 200) {
              acc[`feature${index + 1}`] = data.result;
            }
          } else {
            console.error(`Request ${index + 1} failed:`, response.reason);
          }
          return acc;
        },
        { ...homeContentFeature } // Initialize with the current state
      );

      setHomeContentFeature(updatedContent);
    } catch (error) {
      console.error("Something went wrong. Please try again.", error);
    }
  };

  useEffect(() => {
    const fetchContent = async () => {
      await getStaticFeatureContent();
    };
    fetchContent();
  }, []);

  const [staticLink, setStaticLink] = useState({
    link1: "",
    link2: "",
    // Initialize with empty strings for each link type
  });

  const { link1, link2 } = staticLink;

  const getStaticLink = async () => {
    try {
      const featureTypes = ["Google_Play_Link", "App_Store_Link"];
      const apiRequests = featureTypes.map((type) =>
        axios.get(apiConfig.viewStaticContent, {
          params: { type },
        })
      );

      const responses = await Promise.allSettled(apiRequests);

      console.log("aqqqaaqaqqqqaaaaaqqq", responses);
      const updatedContent = responses.reduce(
        (acc, response, index) => {
          if (response.status === "fulfilled") {
            // Check if the request was successful
            const { data } = response.value;
            if (data.responseCode === 200) {
              acc[`link${index + 1}`] = data.result.description;
            }
          } else {
            console.error(`Request ${index + 1} failed:`, response.reason);
          }
          return acc;
        },
        { ...staticLink } // Initialize with the current state
      );

      setStaticLink(updatedContent);
    } catch (error) {
      console.error("Something went wrong. Please try again.", error);
    }
  };

  useEffect(() => {
    const fetchContent = async () => {
      await getStaticLink();
    };
    fetchContent();
  }, []);

  useEffect(() => {
    let paths = window.location.pathname.split("/");
    if (paths.includes("success") && auth?.userLoggedIn) {
      setShowPopup(true);
    }
  }, []);

  const handleClosePopup = () => {
    console.log("in show popup");
    setShowPopup(false);
  };

  return (
    <>
      <SignInPage
        open={openSignIn}
        toggleSignup={handleSignUpTogle}
        onClose={handleSignInToggle}
        toggleForgot={handleForgotTogle}
      />
      <SignUpPage
        open={openSignUp}
        toggleSignin={handleSignInToggle}
        onClose={handleSignUpTogle}
      />
      <ForgotPassword
        open={openForgot}
        toggleForgot={handleForgotTogle}
        toggleOTP={handleOTPTogle}
        setEmail={setEmail}
        onClose={handleForgotInClose}
      />
      <VerifyOTP
        open={openOTP}
        onClose={handleOTPInClose}
        email={email}
        toggleReset={handleResetTogle}
      />
      <ResetPassword
        open={openReset}
        onClose={handleResetInClose}
        email={email}
      />

      <HomeVideo></HomeVideo>

      <Container
        maxWidth
        style={{
          marginTop: "-6px",
          background: "linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%)",
          padding: "0 11.5%",
        }}
      >
        <Grid
          container
          sx={{
            paddingTop: "4.5rem",
            flexWrap: "wrap",
            flexDirection: "unset",
          }}
        >
          <Grid item sm={12} md={6} lg={6}>
            <Box>
              <img style={{ width: "100%" }} src="/main.png" alt="img"></img>
            </Box>
            <Box>
              <Typography
                sx={{
                  padding: "32px 0 0 0",
                  fontSize: "1.8rem",
                  textAlign: "left",
                  wordBreak: "break-word",
                  fontWeight: 1000,
                  "-webkit-text-stroke-width": "2px",
                  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  fontFamily: "Nunito, sans-serif",
                  fontStyle: "normal",
                  "@media(max-width:500px)": {
                    fontSize: "1.5rem",
                  },
                  "@media(max-width:340px)": {
                    textAlign: "justify",
                    fontSize: "1.3rem",
                  },
                }}
              >
                {homeContent["section1"]?.title}
              </Typography>
              <Typography
                style={{
                  fontSize: "13px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  marginTop: "1vh",
                  fontFamily: "Nunito, sans-serif",
                  "@media(max-width:1024px)": { padding: "10px 10px 40px 0" },
                }}
              >
                <Box
                  component={"div"}
                  dangerouslySetInnerHTML={{
                    __html: homeContent["section1"]?.description || "",
                  }}
                ></Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={12} md={6} lg={6}>
            <Box
              sx={{
                display: "flex",
                "@media(max-width:768px)": {
                  marginTop: "2rem",
                  justifyContent: "center",
                },
              }}
            >
              <Box className={classes.msgContainer}>
                <img
                  className={classes.msgImage}
                  src="./Union.png"
                  alt="img"
                ></img>
                <Box sx={{ fontWeight: "620" }} className={classes.msgUp}>
                  Hey, Buddy! <br /> Want to hear a story?
                </Box>
              </Box>
              <Box className={classes.teddiImgContainer}>
                <img
                  className={classes.teddiImg}
                  src="./teddi.png"
                  alt=""
                ></img>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Box sx={{ marginBottom: "2vh" }}>
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "left",
                margin: "8px 0",
              }}
            >
              Download Now On
            </Typography>
            <Box
              gap={4}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "@media(max-width:425px)": {
                  gap: 1,
                  justifyContent: "center",
                },
              }}
            >
              <Box
                onClick={googleApp}
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid black",
                  height: "3.0rem",
                  minWidth: "13vw",
                  borderRadius: "12px",
                  padding: "3px 12px",
                  "@media(max-width:425px)": {
                    height: "2.8rem",
                    width: "100%",
                  },
                  "@media(max-width:375px)": {
                    padding: "3px 16px 3px 0px",
                    width: "100%",
                  },
                  "@media(max-width:340px)": {
                    padding: "3px 8px 3px 0px",
                    width: "100%",
                  },
                }}
              >
                <img
                  style={{
                    width: "43px",
                    height: "40px",
                    paddingLeft: "5px",
                  }}
                  src="/playstore.png"
                  alt="logo"
                ></img>
                <Box
                  sx={{
                    paddingLeft: "5px",

                    "@media(max-width:340px)": { paddingLeft: "0px" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "11px",
                      "@media(max-width:375px)": { fontSize: "11px" },
                    }}
                  >
                    Get it on
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      "@media(max-width:1024px)": { fontSize: "14px" },
                      "@media(max-width:425px)": { fontSize: "14px" },
                      "@media(max-width:375px)": { fontSize: "11.5px" },
                    }}
                  >
                    Google Play
                  </Typography>
                </Box>
              </Box>
              <Box
                onClick={appleApp}
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  border: "1px solid black",
                  height: "3.0rem",
                  minWidth: "13vw",
                  borderRadius: "12px",
                  padding: "3px 12px",
                  justifyContent: "center",
                  "@media(max-width:425px)": {
                    height: "2.8rem",
                    padding: "3px, 6px",
                    width: "100%",
                  },
                  "@media(max-width:375px)": {
                    padding: "3px, 2px",
                    width: "100%",
                  },
                }}
              >
                <IconButton
                  sx={{
                    color: "white",
                    paddingLeft: "5px",
                    "@media(max-width:340px)": { padding: "0" },
                  }}
                >
                  <AppleIcon
                    sx={{
                      fontSize: "3rem",
                      "@media(max-width:425px)": { fontSize: "2.3rem" },
                      "@media(max-width:340px)": { fontSize: "2rem" },
                    }}
                  />
                </IconButton>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      "@media(max-width:428px)": { fontSize: "12px" },
                      "@media(max-width:375px)": { fontSize: "11px" },
                      "@media(max-width:340px)": { fontSize: "9px" },
                    }}
                  >
                    Download on the
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      "@media(max-width:1024px)": { fontSize: "14px" },
                      "@media(max-width:425px)": { fontSize: "15px" },
                      "@media(max-width:375px)": { fontSize: "13px" },
                    }}
                  >
                    App Store
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={"60px"}
            sx={{
              "@media(max-width:768px)": { marginTop: "0", gap: "8px" },
              "@media(max-width:425px)": { marginTop: "0", gap: "8px" },
            }}
            gap={"8px"}
            flexWrap={"wrap"}
          >
            <Box
              sx={{
                width: "82px",
                height: "100%",
                backgroundColor: "#fff",
                border: "1px solid #00c6ff",
                padding: "10px 20px 6px 20px",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                "@media(max-width:768px)": { width: "100%" },
                "@media(max-width:425px)": { width: "100%" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.6rem",
                  "@media(max-width:425px)": { fontSize: "1.1rem" },
                }}
              >
                10 M+
              </Typography>
              <Typography
                sx={{
                  marginTop: "5px",
                  fontSize: "14px",
                  "@media(max-width:425px)": { fontSize: "12px" },
                }}
              >
                Downloads
              </Typography>
            </Box>
            <Box
              sx={{
                width: "82px",
                height: "100%",
                backgroundColor: "#fff",
                border: "1px solid #00c6ff",
                padding: "10px 20px 6px 20px",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                "@media(max-width:768px)": { width: "100%" },
                "@media(max-width:425px)": { width: "100%" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.6rem",
                  "@media(max-width:425px)": { fontSize: "1.1rem" },
                }}
              >
                15 M+
              </Typography>
              <Typography
                sx={{
                  marginTop: "5px",
                  fontSize: "14px",
                  "@media(max-width:425px)": { fontSize: "12px" },
                }}
              >
                Reviews
              </Typography>
            </Box>
            <Box
              sx={{
                width: "82px",
                height: "100%",
                backgroundColor: "#fff",
                border: "1px solid #00c6ff",
                padding: "10px 20px 6px 20px",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                "@media(max-width:768px)": { width: "100%" },
                "@media(max-width:425px)": { width: "100%", height: "44%" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.6rem",

                  "@media(max-width:425px)": { fontSize: "1.1rem" },
                }}
              >
                <img
                  style={{
                    height: "20px",
                    paddingRight: "4px",
                  }}
                  src="star.png"
                  alt="logo"
                />
                4.5
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "5px",
                  "@media(max-width:425px)": { fontSize: "12px" },
                }}
              >
                Ratings
              </Typography>
            </Box>
          </Box> */}
        </Box>
        {/* <Grid container  >
  <Grid item  sm={12} sx={{ textAlign: "center", paddingTop: "15%" }}>
    <Box>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "60px",
          WebkitTextStrokeWidth: "4px",
          WebkitTextStrokeColor: "#000048",
          WebkitTextFillColor: "#00BAFF",
          fontWeight: 700,
          fontFamily: "Rowdies, sans-serif",
          letterSpacing: "-2px",
          "@media(max-width:400px)": {
            fontSize: "3rem",
          },
        }}
      >
        Start To Use Teddy Tales
      </Typography>
     
            <Typography
              sx={{
                fontFamily: "Nunito, sans-serif",
                fontWeight: 900,
                fontSize: "20px",
                lineHeight:'50px',
                WebkitTextStroke: "1px",
                color: "#000",
                "@media(max-width:600px)": {
                  fontSize: "1.6rem",
                },
                textTransform: "uppercase"
              }}
            >
             TO REACH CHILDREN, CREATE THEIR KNOW WORLD WITH TEDDY FRIEND
            </Typography>
            <Typography>

            </Typography>
    </Box>
  </Grid>
</Grid> */}
      </Container>

      {/* ---------------- Third Section ------------------- */}

      <Container
        className={classes.shape}
        maxWidth
        style={{ background: "#00C6FF", padding: "0" }}
      >
        <Grid container>
          <Grid
            item
            sm={12}
            md={12}
            lg={5}
            sx={{
              marginTop: "2.5rem",
              paddingLeft: "7%",
              "@media(max-width:1025px)": {
                padding: "0 8%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: "100%",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img style={{}} src="/logo2.png" alt=""></img>
              </Box>
              <Typography
                sx={{
                  fontSize: "3.5rem",
                  WebkitTextStrokeWidth: "4px",
                  WebkitTextStrokeColor: "#000048",
                  WebkitTextFillColor: "#FFDC00",
                  fontWeight: 700,
                  fontFamily: "Rowdies, sans-serif",
                  wordSpacing: "-6px",
                  letterSpacing: "-3px",
                  "@media (min-width: 1024px) and (max-width: 1416px)": {
                    fontSize: "3rem",
                  },
                  "@media (max-width: 770px)": {
                    fontSize: "4rem",
                  },
                  "@media (max-width: 550px)": {
                    fontSize: "2.6rem",
                    WebkitTextStrokeWidth: "3px",
                    wordSpacing: "-2px",
                    fontWeight: 400,
                  },
                  "@media (max-width: 375px)": {
                    fontSize: "2.4rem",
                    WebkitTextStrokeWidth: "2.5px",
                    fontWeight: 400,
                  },
                  "@media (max-width: 340px)": {
                    fontSize: "2.1rem",
                  },
                }}
              >
                {homeContent["section2"]?.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.1rem",
                  fontFamily: "Nunito, sans-serif",
                  fontWeight: 900,
                  "@media (min-width: 1024px) and (max-width: 1416px)": {
                    fontSize: "1.8rem",
                  },
                  "@media(max-width:550px)": {
                    fontSize: "1.5rem",
                    fontWeight: 700,
                  },
                  "@media(max-width:375px)": {
                    fontSize: "1.4rem",
                    fontWeight: 700,
                  },
                  "@media(max-width:340px)": {
                    fontSize: "1.2rem",
                  },
                }}
              >
                {homeContent["section2"]?.subtitle}
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontFamily: "Nunito, sans-serif",
                  textAlign: "justify",
                  padding: "0 40px 0 40px",
                  "@media (min-width: 1024px) and (max-width: 1416px)": {
                    width: "84%",
                  },
                  "@media(max-width:1025px)": { width: "58%" },
                  "@media(max-width:770px)": { width: "65%" },
                  "@media(max-width:425px)": { width: "95%", padding: "0" },
                }}
              >
                <Box
                  component={"div"}
                  dangerouslySetInnerHTML={{
                    __html: homeContent["section2"]?.description || "",
                  }}
                ></Box>
              </Typography>
            </Box>
            {/** {homeContent["section3"]?.keyFeatures} */}
            <Box
              gap={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                marginTop: "1.5rem",
                "@media (min-width: 1024px) and (max-width: 1416px)": {
                  gap: 2,
                },
                "@media(max-width:770px)": {
                  gap: 2,
                },
                "@media(max-width:600px)": {
                  gap: 1,
                },
              }}
            >
              <Box sx={styles.capsuleBack}>
                <Typography
                  sx={{
                    fontFamily: "Nunito, sans-serif",
                    fontWeight: "900",
                    textAlign: "center",
                    paddingTop: "30px",
                    "@media (min-width: 1024px) and (max-width: 1416px)": {
                      fontSize: "14px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:550px)": {
                      paddingTop: "12px",
                    },
                    "@media(max-width:425px)": {
                      fontSize: "13px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:360px)": {
                      fontSize: "14px",
                      paddingTop: "7px",
                    },
                  }}
                >
                  {homeContentFeature["feature1"]?.description}
                </Typography>
              </Box>
              <Box sx={styles.capsuleBack}>
                <Typography
                  sx={{
                    fontFamily: "Nunito, sans-serif",
                    fontWeight: "900",
                    textAlign: "center",
                    paddingTop: "30px",
                    "@media (min-width: 1024px) and (max-width: 1416px)": {
                      fontSize: "14px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:550px)": {
                      paddingTop: "12px",
                    },
                    "@media(max-width:425px)": {
                      fontSize: "13px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:360px)": {
                      fontSize: "14px",
                      paddingTop: "7px",
                    },
                  }}
                >
                  {homeContentFeature["feature2"]?.description}
                </Typography>
              </Box>
            </Box>
            <Box
              gap={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",

                "@media (min-width: 1024px) and (max-width: 1416px)": {
                  gap: 2,
                },
                "@media(max-width:770px)": {
                  gap: 2,
                },
                "@media(max-width:600px)": {
                  gap: 1,
                },
              }}
            >
              <Box sx={styles.capsuleBack}>
                <Typography
                  sx={{
                    fontFamily: "Nunito, sans-serif",
                    fontWeight: "900",
                    textAlign: "center",
                    paddingTop: "30px",
                    "@media (min-width: 1024px) and (max-width: 1416px)": {
                      fontSize: "14px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:550px)": {
                      paddingTop: "12px",
                    },
                    "@media(max-width:425px)": {
                      fontSize: "13px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:360px)": {
                      fontSize: "14px",
                      paddingTop: "7px",
                    },
                  }}
                >
                  {homeContentFeature["feature3"]?.description}
                </Typography>
              </Box>
              <Box sx={styles.capsuleBack}>
                <Typography
                  sx={{
                    fontFamily: "Nunito, sans-serif",
                    fontWeight: "900",
                    textAlign: "center",
                    paddingTop: "30px",
                    "@media (min-width: 1024px) and (max-width: 1416px)": {
                      fontSize: "14px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:550px)": {
                      paddingTop: "12px",
                    },
                    "@media(max-width:425px)": {
                      fontSize: "13px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:360px)": {
                      fontSize: "14px",
                      paddingTop: "7px",
                    },
                  }}
                >
                  {homeContentFeature["feature4"]?.description}
                </Typography>
              </Box>
            </Box>
            <Box
              gap={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                // marginTop: "-10px",
                "@media (min-width: 1024px) and (max-width: 1416px)": {
                  gap: 2,
                },
                "@media(max-width:770px)": {
                  gap: 2,
                },
                "@media(max-width:600px)": {
                  gap: 1,
                },
              }}
            >
              <Box sx={styles.capsuleBack}>
                <Typography
                  sx={{
                    fontFamily: "Nunito, sans-serif",
                    fontWeight: "900",
                    textAlign: "center",
                    paddingTop: "30px",
                    "@media (min-width: 1024px) and (max-width: 1416px)": {
                      fontSize: "14px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:550px)": {
                      paddingTop: "12px",
                    },
                    "@media(max-width:425px)": {
                      fontSize: "13px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:360px)": {
                      fontSize: "14px",
                      paddingTop: "7px",
                    },
                  }}
                >
                  {homeContentFeature["feature5"]?.description}
                </Typography>
              </Box>
              <Box sx={styles.capsuleBack}>
                <Typography
                  sx={{
                    fontFamily: "Nunito, sans-serif",
                    fontWeight: "900",
                    textAlign: "center",
                    paddingTop: "30px",
                    "@media (min-width: 1024px) and (max-width: 1416px)": {
                      fontSize: "14px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:550px)": {
                      paddingTop: "12px",
                    },
                    "@media(max-width:425px)": {
                      fontSize: "13px",
                      paddingTop: "20px",
                    },
                    "@media(max-width:360px)": {
                      fontSize: "14px",
                      paddingTop: "7px",
                    },
                  }}
                >
                  {homeContentFeature["feature6"]?.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={7}
            sx={{
              height: "80vh",
              "@media(max-width:1025px)": {
                height: "90vh",
              },
              "@media(max-width:600px)": {
                height: "60vh",
              },
            }}
          >
            <Box className={classes.phoneContainer}>
              <img
                className={classes.phoneImage}
                src="./GroupPhone.png"
                alt="img"
              ></img>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* -------------- Forth Section ------------------ */}
      {/* TESTIMONIAL */}
      <a href="#abc" ref={aref}></a>
      <Container
        maxWidth
        id="abc"
        style={{
          background: "linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%)",
          paddingBottom: "3%",
        }}
      >
        <Grid container>
          <Grid item sm={12} sx={{ textAlign: "center", paddingTop: "15%" }}>
            <Typography
              sx={{
                fontFamily: "Nunito, sans-serif",
                fontWeight: 800,
                fontSize: "1.5rem",
                WebkitTextStroke: "1px",
                color: "#000",
                "@media(max-width:600px)": {
                  fontSize: "1.6rem",
                },
                textTransform: "uppercase",
              }}
            >
              You Are The Center Of Our Universe
            </Typography>
            <Typography
              sx={{
                fontSize: "4rem",
                WebkitTextStrokeWidth: "4px",
                WebkitTextStrokeColor: "#000048",
                WebkitTextFillColor: "#00BAFF",
                fontWeight: 700,
                fontFamily: "Rowdies, sans-serif",
                letterSpacing: "-2px",
                "@media(max-width:400px)": {
                  fontSize: "3rem",
                },
              }}
            >
              Testimonials
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          lg={12}
          sx={{
            width: "82%",
            paddingBottom: "2rem",
            margin: "0 auto",
          }}
        >
          <Slider {...settings} ref={sliderRef}>
            {homeContent &&
              homeContent["section4"]?.map((item, index) => {
                return (
                  <Box
                    key={`section4_${index}`}
                    className={"testimonial"}
                    sx={{
                      width: "300px",
                      height: "400px",
                      position: "relative",
                      marginTop: "50px",
                      transition: "transform 0.7s ease",
                    }}
                  >
                    <Box className={classes.up}>{testimonialLayer1}</Box>
                    <Box className={classes.profile_container}>
                      <img
                        className={classes.profile}
                        src={item?.image}
                        onError={(e) => (e.target.src = "./profileIcon.png")}
                        alt={item?.userName}
                      />
                    </Box>
                    <Box className={classes.text_section}>
                      <Box className={classes.headingT}>{item?.userName}</Box>
                      <Box className={classes.sub_heading}>
                        {item?.designation}
                      </Box>
                      <Box className={classes.inverted}>
                        <img src="./comma.png" alt="img"></img>
                      </Box>
                      <Box className={classes.textT}>{item?.feedback}</Box>
                    </Box>
                  </Box>
                );
              })}
          </Slider>
        </Grid>
      </Container>
      <Container
        maxWidth
        style={{
          background: "linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%)",
          paddingBottom: "3%",
        }}
      >
        <Box mt={-5}>
          <Typography
            variant="h1"
            sx={{
              fontFamily: "Rowdies",
              fontSize: "4rem",
              fontWeight: "700",
              lineHeight: "50px",
              letterSpacing: "0.03em",
              textAlign: "center",
              WebkitTextStrokeWidth: "4px",
              WebkitTextStrokeColor: "#000048",
              WebkitTextFillColor: "#00BAFF",
              "@media(max-width:400px)": {
                fontSize: "3rem",
              },
            }}
          >
            Buy Teddy
          </Typography>
        </Box>

        <Grid
          container
          display="flex"
          justifyContent="center"
          sx={{ padding: "0 12%", marginTop: "60px" }}
        >
          <Grid item lg={3}>
            {/* <Box id="buy-button"></Box> */}
            {/* <Box id="buy-button"></Box> */}
            <Element name="teddySection">
              <Box>
                {loader ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="inherit" />
                    {/* <ShimmerPostItem  card title text cta   imageWidth={80}
          imageHeight={80}  /> */}
                  </Box>
                ) : productData ? (
                  <Card
                    classes={classes.buyCard}
                    variant="outlined"
                    sx={{ width: 285 }}
                  >
                    <CardContent>
                      <Box className={classes.buyBox}>
                        <Box className={classes.imageContainer}>
                          {productData.imageUrl && (
                            <img
                              className={classes.teddiBuyImg}
                              src={productData.imageUrl}
                              alt={productData.name}
                            />
                          )}
                        </Box>
                        <Box>
                          <Typography
                            variant=""
                            className={classes.buyName}
                            component="div"
                          >
                            {productData.name}
                          </Typography>
                          {/* <Typography variant="body2" className={classes.buyName} color="text.secondary">
                {productData.description}
              </Typography> */}
                          {/* {productData.unitAmount !== undefined && ( */}
                          <Typography
                            variant=""
                            sx={{ fontSize: "28px", fontWeight: 700 }}
                          >
                            {/* ${productData.unitAmount} */}${price}
                          </Typography>
                          {/* )} */}
                        </Box>
                      </Box>
                    </CardContent>
                    {productData && (
                      <CardActions
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Button
                          onClick={() => handleGetStarted()}
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            padding: "3%",
                            fontSize: "15px",
                            fontWeight: 600,
                            borderRadius: "5px",
                          }}
                          fullWidth
                        >
                          Buy
                        </Button>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "rgb(110, 110, 110)",
                              fontSize: "13px",
                            }}
                            mt={2}
                            mb={1}
                          >
                            Supported payment methods:
                          </Typography>
                        </Box>
                        <Box display="flex" gap="8px" mb={2}>
                          <img src="./card.svg" alt="Card" />
                          <img src="./master.svg" alt="MasterCard" />
                          <img src="./visa.svg" alt="Visa" />
                          <img src="./google_pay.svg" alt="Google Pay" />
                          <img src="./cashapp.svg" alt="Cash App" />
                        </Box>
                      </CardActions>
                    )}
                  </Card>
                ) : (
                  <Typography>No product found.</Typography>
                )}
              </Box>
            </Element>
          </Grid>
        </Grid>
        <Subscription />
      </Container>

      {showPopup && (
        <SuccessSubscription
          message="Thank you for purchasing the plan!"
          handleClosePopup={handleClosePopup}
        />
      )}
    </>
  );
}
