export const baseurl = process.env.REACT_APP_BASE_URL;
// export const baseurl ="http://172.16.6.83:2066"
// export const baseurl = "http://localhost:2066"
let user = `${baseurl}/api/v1/user`;
let admin = `${baseurl}/api/v1/admin`;
let staticContent = `${baseurl}/api/v1/static`;
let contact = `${baseurl}/api/v1/contact`;
let subscription = `${baseurl}/api/v1/subscription`;
let order = `${baseurl}/api/v1/order`;
let testmonial = `${baseurl}/api/v1/testmonial`;   

const Apiconfigs = {
  profile: `${user}/profile`,
  signup: `${user}/signup`,
  webhookHandler: `${user}/webhookHandler`,
  login: `${user}/login`,
  verifyOTP: `${user}/verifyOTP`,
  forgotPassword: `${user}/forgotPassword`,
  resetPassword: `${user}/resetPassword`,
  resendOTP: `${user}/resendOTP`,
  deactivateUserAccount: `${user}/deactivateUserAccount`,
  listTransaction: `${subscription}/listTransaction`,
  editProfile: `${user}/editProfile`,
  changePassword: `${user}/changePassword`,
  socialLogin: `${user}/socialLogin`,
  createCheckoutSession: `${user}/create-checkout-session`,
  createCheckoutSubscription:`${user}/create-checkout-subscription`,
  logOut: `${user}/logOut`,
  ipGet: `${user}/ip-get`,
  viewStaticContent: `${staticContent}/viewStaticContent`,   
  addNewsLatter: `${contact}/addNewsLatter`,
  contactUs: `${contact}/contactUs`,
  faqList: `${staticContent}/faqList`,
  listSubscription: `${subscription}/listSubscription`,
  getInvoice: `${subscription}/getInvoice`,
  orderList: `${order}/orderList`,

  listTestmonial: `${testmonial}/listTestmonial`,
  stripeProducts: "https://api.stripe.com/v1/products?limit=100",
  stripeProductsPrices: "https://api.stripe.com/v1/prices",
  // stripeBuyTeddy: "https://merchant-ui-api.stripe.com/buy-button/buy_btn_1PJuKuGl0ifWPp5x8lq25Ovq",
};

export default Apiconfigs;
