import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import axios from "axios";
import apiConfig from "./../../apiConfig/config";

const styles = {
  textStyle: {
    color: "#636880",
    fontFamily: "Nunito, sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    textAlign: "justify",
    lineHeight: "20px",
    margin: "1rem 0",
  },
};

const TermsCondition = () => {
  const [content, setContent] = useState("");
  const getStaticContent = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.viewStaticContent,
        params: {
          type: "termsConditions",
        },
      });
      if (res.data.responseCode == 200) {
        // setContent(res.description);
        console.log(res)
        setContent(res.data.result.description)
      } else {
        console.log(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
        "Something went wrong. Please try again."
      );
    }
  };

  useEffect(() => {
    getStaticContent()
  }, [])
  return (
    <>
      <Grid container sx={{ padding: "0 11.5%", minHeight: "80vh" }}>
        <Box
          component={"div"}
          dangerouslySetInnerHTML={{
            __html: `${content}`,
          }}
        ></Box>
      </Grid>
    </>
  );
};

export default TermsCondition;
