import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/Auth";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { postDataHandler } from "../../../src/apiConfig/service";

function ForgotPassword({
  open,
  openforgot,
  onClose,
  toggleOTP,
  toggleForgot,
  setEmail,
}) {
  // const classes = useStyles();

  const [isLoading, setIsLoading] = useState();

  // const handleSignInClose = () => {
  //   onClose();
  // };

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setIsLoading(true);

      const response = await postDataHandler("forgotPassword", values);

      if (response.responseCode === 200) {
        setIsLoading(false);
        toast.success(response?.responseMessage);
        setEmail(values.email);
        toggleOTP(); // Call toggleOTP function to show/hide OTP popup
        onClose();
      }
      setIsLoading(false);
    } catch (err) {
      // toast.error(err?.response?.data?.responseMessage);
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address.")
      .required("Please enter email address."),
  });

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: "400px",
          padding: "2.5rem",
          border: "1px solid #e7e7e7",
          "@media(max-width:600px)": {
            width: "330px",
            padding: "0.5rem",
          },
          "@media(max-width:380px)": {
            width: "310px",
          },
          "@media(max-width:330px)": {
            width: "262px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
            // borderRadius:"20px"
          }}
        >
          <img src="/lock.png" height="53px" width="53px" alt="lock" />
          <Typography
            sx={{
              color: "#000",
              fontSize: "20px",
              fontWeight: 700,
              fontFamily: "Nunito",
              marginTop: "10px",
              textTransform: "capitalize",
              "@media(max-width:600px)": {
                fontSize: "16px",
              },
            }}
          >
            Forgot Password
          </Typography>
          <Typography
            sx={{
              width: "314px",
              height: "40px",
              fontSize: "12px",
              fontFamily: "Nunito",
              textAlign: "center",
              color: "#636880",
              marginTop: "5px",
              lineHeight: "20px",
            }}
          >
            Enter your email for the verification process, we will send 6 digits
            code to your email
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, handleChange, handleBlur, isValid }) => (
            <Form>
              <Box gap={2} sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  fullWidth
                  placeholder="Enter Your Email"
                  variant="outlined"
                  size="small"
                  id="email"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "Nunito",

                      height: "44px",
                      gap: "10px",
                      padding: "8px",
                      borderRadius: "5px",
                    },
                  }}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0.1%",
                    },
                  }}
                />
              </Box>
              <Box
                maxWidth
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></Box>
              <Box sx={{ marginTop: "10px" }}>
                <Button
                  // onClick={handleSignInClose}
                  style={{
                    height: "44px",
                    padding: "8px",
                    gap: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#00C6FF",
                  }}
                  type="submit"
                  fullWidth
                  disabled={!isValid || isLoading}
                  variant="contained"
                  // disabled={isLoading}
                  // LinkComponent={NavLink}
                  // to="/ssubscription"
                >
                  {isLoading ? (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  ) : (
                    "CONTINUE"
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}

export default ForgotPassword;
