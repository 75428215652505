import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { Box, Grid, Typography, Button, Divider } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  orderDelivered,
  orderCancelled,
  orderInProcess,
  placeMarker,
  convertDateTime
} from "./../../utils"
import { useNavigate, useLocation } from 'react-router-dom';

const StyledComponent = styled("div")({
  fontFamily: "Nunito",
  "& h2": {
    color: "#161E29",
    fontSize: "45px",
    fontStyle: "normal",
    fontFamily: "Nunito, sans-serif",
    fontWeight: 800,
  },
  "& h6": {
    fontFamily: "Nunito",
    color: "#161E29",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "25px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "& h4": {
    fontFamily: "Nunito",
    color: "#161E29",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "25px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "& .MuiTypography-body2": {
    cursor: "pointer",
    fontFamily: "Nunito",
  },
});

const MyCart = () => {
  const location = useLocation();
  console.log("sdfgsdfg", location.state)
  const navigate = useNavigate();
  const [orderDetails, setorderDetails] = useState(location?.state?.orderData)
  return (
    <StyledComponent>
      <Box sx={{ padding: "0 11.5%", minHeight: "80vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2rem 0 2rem 0"
          }}
        >
          <Typography sx={{
            color: "#262626",
            fontWeight: 800,
            fontSize: "2.5rem",
            fontFamily: "Nunito",
            '@media(max-width:650px)': {
              fontSize: "1.5rem"
            }
          }}>Order Details</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                borderRadius: "10px",
                padding: "20px 20px 0 20px",
                boxShadow:6,
                borderRadius:"10px",
                backgroundColor: "#FAFAFA",
              }}
            >
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{ display: "flex", justifyContent:'space-between',gap: "18px", padding: "0 0 10px 0" }}
                  >
                    <Box sx={{ display:"flex",
                        flexDirection:"row",
                        gap:'20px'}}>
                    <Box
                      sx={{
                        maxWidth: "38px",
                        borderRadius: "10px",
                       
                      }}
                    >
                      {(orderDetails?.status === "DELEVERED" ? orderDelivered : orderDetails?.status === "PENDING" ? orderInProcess : orderCancelled)}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h6">  {(orderDetails?.status === "DELEVERED" ? "Delivered" : orderDetails?.status === "PENDING" ? "Delivery In Pending" : "Delivery Cancelled")}</Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.60)" }}
                      >
                        {convertDateTime(orderDetails?.createdAt)}
                      </Typography>
                    </Box>
                    </Box>
                    <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#00C6FF",
                        borderColor: "rgb(88 198 255)",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        navigate("/my-order");
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                  </Box>
                  <Divider sx={{ borderColor: "#ececec" }} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "18px",
                      padding: "10px 0 10px 0",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #d7d7d7",
                        borderRadius: "8px",
                        height: "85px",
                        width: "65px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ height: "52px", width: "50px" }}
                        src={orderDetails?.image || "teddi.png"}
                        alt="product details"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                       
                      }}
                    >
                      <Typography variant="h6">{orderDetails?.name}</Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.60)" }}
                      >
                        {`order_${orderDetails?._id}`}
                      </Typography>
                      <Typography variant="h6">${orderDetails?.totalPrice}</Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ borderColor: "#ececec" }} />
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    padding: "30px 14px",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography variant="h4" sx={{ textDecoration: "underline" }}>
                    Address:
                  </Typography>
                  <Box sx={{display:"flex"}}>
                  <Box  sx={{paddingTop: "10px",}} >
                  {placeMarker}&nbsp;&nbsp;
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "rgba(0, 0, 0, 0.60)", paddingTop: "10px", display: "flex", alignItems: "center" }}
                  >
                    
                    {orderDetails?.shippingAddress?.address?.line1 && `${orderDetails?.shippingAddress?.address?.line1}`}
                    {orderDetails?.shippingAddress?.address?.line2 && ` ,${orderDetails?.shippingAddress?.address?.line2} `}
                    {orderDetails?.shippingAddress?.address?.city && ` ,${orderDetails?.shippingAddress?.address?.city} `}
                    {orderDetails?.shippingAddress?.address?.state && ` ,${orderDetails?.shippingAddress?.address?.state} `}
                    <br/> {orderDetails?.shippingAddress?.address?.postal_code && ` ,${orderDetails?.shippingAddress?.address?.postal_code}`}
                    {orderDetails?.shippingAddress?.address?.country && ` ,${orderDetails?.shippingAddress?.address?.country}`}
                  </Typography>
                 
                </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </StyledComponent>
  );
};

export default MyCart;
