import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};

const Buyfirst = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // marginBottom: '20px',
          }}
        >
          <img src="/newlogo.png" height="136px" width="136px" alt="logo" />
          <Typography
            sx={{
              color: '#000',
              fontSize: '24px',
              fontWeight: 700,
              fontFamily: 'Nunito',
              textTransform: 'none',
              '@media(max-width:600px)': {
                fontSize: '16px',
              },
            }}
          >
        Please buy the teddy first
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 ,mb:2 }}>
          <NavLink to="/?scrollTo=teddySection" style={{ textDecoration: 'none' }}>
            <Button 
              onClick={handleClose} 
              sx={{ 
                backgroundColor: '#00C6FF', 
                color: '#fff', 
                '&:hover': {
                  backgroundColor: '#0890c5',
                },
                borderRadius: '8px',
                fontSize: '16px',
                fontWeight:"bold",
                boxShadow:3
              }}
            >
              Ok
            </Button>
          </NavLink>
        </Box>
      </Box>
    </Modal>
  );
};

export default Buyfirst;
