import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  Paper,
  IconButton,
  Button,
  Checkbox,
} from "@mui/material";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { AiFillTrophy } from "react-icons/ai";
import CheckIcon from "@mui/icons-material/Check";
import Apiconfigs from "./../../apiConfig/config";
import { AuthContext } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import Buyfirst from "../../pages/Buyfirst/Buyfirst"
export default function PlansCard({
  card,
  location,
  handleSignUpTogle,
  handleSignInTogle,
  openSignUp,
  openSignIn,
  openForgot,
  openOTP,
  handleForgotTogle,
  handleOTPTogle,
  handleSignInClose,
  handleSignInOpen,
  handleForgotInClose,
  handleOTPInClose,
  auth,
  SignInPage,
  SignUpPage,
  ForgotPassword,
  VerifyOTP,
  ResetPassword,
  setEmail,
  openReset,
  handleResetTogle,
  handleResetInClose,
  email,
}) {
  const stripe = useStripe();
  const [loader, setLoader] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: 1,
    limit: 10,
  });

  const planUrls = {
    basic: "https://buy.stripe.com/test_9AQ29Tdnt8fr94QdQS",
    premium: "https://buy.stripe.com/test_6oEg0Jcjpanzch28wz",
  };

  const [myId, setMyId] = useState("");

  console.log(card, "dsfsdfsdfsdfsdfsdf");
  useEffect(() => {
    userList.map((data) => {
      console.log(data.planName, card.name);
      if (data.planName === card.name) {
        setMyId(data._id);
        console.log(data._id);
      }
    });
  }, [userList, card]);
  console.log("kfkf", myId);
  // const handleClickPremium = (planType) => {
  //   const url = planUrls[planType];
  //   if (url) {
  //     window.location.href = url;
  //   } else {
  //     console.error("Invalid plan type");
  //   }
  // };
  useEffect(() => {
    console.log(myId, "dfdfsdfds");
  }, [myId]);

  const getUserList = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: Apiconfigs["listSubscription"],
        data: {
          ...filter,
        },
      });
      if (response?.data?.responseCode === 200) {
        console.log(response?.data?.result?.docs);
        setUserList(response?.data?.result?.docs);
      }
    } catch (err) {
      console.error(err.res);
    }
  };

  const handleClickPremium = async () => {
    try {
      const response = await axios.post(Apiconfigs.createCheckoutSubscription, {
        planId: myId,
      });

      const session = response.data;
      console.log(session.url);
      if (session.url) {
        window.location.href = session.url;
      } else {
        console.error("Session URL not found");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };
  // const handleClickBuy = async (id) => {
  //   try {
  //     setLoader(true);
  //     const res = await axios({
  //       method: "POST",
  //       url: Apiconfigs.createCheckoutSession,
  //       headers: {
  //         token: sessionStorage.getItem("token"),
  //       },
  //       data: {
  //         planId: id,
  //         success_url: `${window.location.origin}/successful`,
  //         cancel_url: `${window.location.origin}/failure`,
  //       },
  //     });
  //     if (res.data.responseCode === 200) {
  //       const result = await stripe.redirectToCheckout({
  //         sessionId: res.data.result.id,
  //       });
  //       console.log(result, "checkout result");
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  // const handleGetStarted = (planName) => {
  //   const url = planUrls[planName.toLowerCase()];
  //   if (
  //     auth?.userLoggedIn &&
  //     auth?.userData?.subscriptionDetails[0]?.subscriptionPlanId?.planName
  //       .length == 0
  //   ) {
  //     navigate("/?scrollTo=teddySection");
  //   } else {
  //     if (auth?.userLoggedIn) {
  //       handleClickPremium(planName.toLowerCase());
  //     }
  //      else {
  //       // setRedirectUrl(url);
  //       handleSignInOpen();
  //     }
  //   }
  // };

  //   const handleGetStarted = (planName) => {
  //   const url = planUrls[planName.toLowerCase()];
  //   if (
  //     auth?.userLoggedIn &&
  //     auth?.userData?.subscriptionDetails[0]?.subscriptionPlanId?.planName
  //       .length == 0
  //   ) {
  //     navigate("/?scrollTo=teddySection");
  //   } else {
  //     if (auth?.userLoggedIn) {
  //       handleClickPremium(planName.toLowerCase());
  //     }
  //      else {
  //       // setRedirectUrl(url);
  //       handleSignInOpen();
  //     }
  //   }
  // };

  const handleGetStarted = (planName) => {
    if (
      auth?.userLoggedIn &&
      auth?.userData?.subscriptionDetails[0]?.subscriptionPlanId?.planName
        .length > 0
    ) {
      handleClickPremium(planName.toLowerCase());
    } else {
      handleModalOpen(); // Open the custom modal instead of calling handleSignInOpen
    }
  };

  // useEffect(() => {
  //   if (auth?.userLoggedIn && redirectUrl) {
  //     window.location.href = redirectUrl;
  //   }
  // }, [auth?.userLoggedIn, redirectUrl]);

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      <Box className="card">
        {auth?.userData?.subscriptionDetails?.length > 0 &&
          [card.name].includes(
            auth?.userData?.subscriptionDetails[0]?.subscriptionPlanId?.planName
          ) && (
            <Box
              sx={{
                backgroundColor: "#1CC600",
                color: "white",
                height: "26px",
                borderRadius: "10px  10px 0 0",
                marginTop: "0.25px",
                padding: "14px",
              }}
            >
              <p>ACTIVE</p>
            </Box>
          )}

        <Box key={card.id} className="cardContent">
          <p className="h3">${card.price}</p>
          <p className="h4">{card.name}</p>
          <p className="body1">{card.description}</p>
        </Box>
        <Box className="features">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: "8px",
              padding: "19px",
              "@media(max-width:500px)": {
                gap: "0",
                padding: "10px",
              },
            }}
          >
            <p className="h5">FEATURES:</p>
            {card.features.map((features, i) => (
              <p key={i} className="benefits">
                <span style={{ marginLeft: "-5px" }}>
                  <CheckIcon
                    sx={{
                      color: "#262626",
                      marginTop: "3px",
                      padding: "0px",
                      transform: "scale(0.6)",
                    }}
                  />
                </span>

                <p className="body1">{features}</p>
              </p>
            ))}
          </Box>
        </Box>
        {((location.pathname !== "/subscription" && !auth?.userLoggedIn) ||
          !card.name.includes("Free")) &&
          (!auth?.userLoggedIn ||
            (auth?.userData?.subscriptionDetails?.length > 0 &&
              ![card.name, "Premium"].includes(
                auth?.userData?.subscriptionDetails[0]?.subscriptionPlanId
                  ?.planName
              ))) && (
            <>
              <Box className="btndiv">
                <SignInPage
                  open={openSignIn}
                  onClose={handleSignInClose}
                  toggleSignup={handleSignUpTogle}
                  toggleForgot={handleForgotTogle}
                />
                <SignUpPage
                  open={openSignUp}
                  toggleSignin={handleSignInTogle}
                  onClose={handleSignUpTogle}
                />
                <ForgotPassword
                  open={openForgot}
                  toggleForgot={handleForgotTogle}
                  toggleOTP={handleOTPTogle}
                  setEmail={setEmail}
                  onClose={handleForgotInClose}
                />
                <VerifyOTP
                  open={openOTP}
                  onClose={handleOTPInClose}
                  email={email}
                  toggleReset={handleResetTogle}
                />
                <ResetPassword
                  open={openReset}
                  onClose={handleResetInClose}
                  email={email}
                />
                <Buyfirst open={open} handleClose={handleModalClose} />

                <Button
                  onClick={() => handleGetStarted(card.name)}
                  disabled={loader}
                  size="small"
                  sx={{
                    width: "120px",
                    backgroundColor: "#00C6FF",
                    border: "1px solid lighyellow",
                    borderRadius: "8px",
                  }}
                  fullWidth
                  variant="contained"
                >
                  {auth?.userLoggedIn ? (
                    auth?.userData?.subscriptionDetails[0]?.subscriptionPlanId
                      ?.planName === "Basic" ||
                    auth?.userData?.subscriptionDetails[0]?.subscriptionPlanId
                      ?.planName === "Premium" ? (
                      <p className="h6">Upgrade</p>
                    ) : (
                      <p className="h6">Subscribe</p>
                    )
                  ) : (
                    <p className="h6">Get Started</p>
                  )}
                </Button>
              </Box>
            </>
          )}
      </Box>
    </>
  );
}
