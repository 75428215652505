import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const Product = ({ image, name, price }) => {
  return (
    <Box
      sx={{
        height: "300px",
        width: "280px",
        background: "#FFFFFF",
        boxShadow: "0px 0px 38.0769px rgba(0, 0, 0, 0.05)",
        borderRadius: "4.23077px",
        padding: "12px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "220px",
          width: "200px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img style={{ height: "210px", width: "190px" }} src={image} alt="" />
      </Box>
      <Box
        sx={{
          width: "202px",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 800,
            fontFamily: "Nunito",
            color: "#000",
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 800,
            fontFamily: "Nunito",
            color: "#000",
          }}
        >
          <span>&#36;</span>
          {price}
        </Typography>
      </Box>
      <Box sx={{ width: "220px", marginTop: "10px" }}>
        <Button
          fullWidth
          LinkComponent={NavLink}
          to="/my-cart"
          variant="outlined"
          sx={{
            border: "1px solid #00C6FF",
            color: "#00C6FF",
            fontSize: "14px",
            fontFamily: "Nunito",
            fontWeight: 400,
            textTransform: "none",
          }}
        >
          Add to Cart
        </Button>
      </Box>
    </Box>
  );
};

export default Product;
