
// SuccessSubscription.js
import React from 'react';
import './successSubscription.css';

const SuccessSubscription = ({ message, handleClosePopup }) => {

  

  return (
    <div className="overlay">
      <div className="popup">
        <h2 className="popup-title">Success!</h2>
        <p className="popup-message">{message}</p>
        <button className="popup-button" onClick={()=>(
           handleClosePopup())}>Close</button>
      </div>
    </div>
  );
};

export default SuccessSubscription;

   